import { Formik } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../ui";

export const ResourceSchema = Yup.object({
  name: Yup.string()
    .min(2, "Le nom de votre salarié est trop court")
    .required("Le nom de votre salarié est obligatoire"),
  coefficient: Yup.number().required(
    "Le coefficient de productivité est nécessaire, mettez 1 si vous ne savez pas quoi mettre"
  ),
});

const coefficients = [
  {
    value: 0.5,
    label: "0.5",
  },
  {
    value: 1,
    label: "1",
  },
  {
    value: 1.5,
    label: "1.5",
  },
];

const ResourceForm = ({ id, resource, onSubmit, className }) => {
  return (
    <Formik
      initialValues={resource}
      validationSchema={ResourceSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, setValues }) => (
        <form
          id={id}
          className={className}
          onSubmit={(e) => {
            const coefficient = parseFloat(values.coefficient);
            if (!isNaN(coefficient)) {
              setValues({ ...values, coefficient });
            }
            handleSubmit(e);
          }}
          aria-label="form"
        >
          <Input
            id="resource_form-name"
            data-testid="resource_form-name"
            label="Nom"
            placeholder="nom de votre salarié"
            name="name"
            className="mb-4"
          />
          <Select
            id="resource_form-coefficient"
            label="Coefficient de productivité"
            name="coefficient"
            menuPlacement="top"
            help="Le coefficient de productivité vous permet de modifier le rendement de votre salarié."
            placeholder="Coefficient de productivité"
            options={coefficients}
          />
        </form>
      )}
    </Formik>
  );
};

export default ResourceForm;
