import { useField } from "formik";
import Label from "./Label";
import HelpMessage from "./HelpMessage";

export const Input = ({ label, className, help, ...props }) => {
  const [field, meta] = useField(props);
  const { value, ...rest } = field;
  return (
    <div className={`mb-4 ${className}`}>
      <Label htmlFor={props.id || props.name}>{label}</Label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          className="form-input block w-full sm:text-sm sm:leading-5"
          value={value || ""}
          {...rest}
          {...props}
        />
      </div>
      {meta.touched && meta.error ? (
        <HelpMessage className="text-red-800">{meta.error}</HelpMessage>
      ) : help ? (
        <HelpMessage>{help}</HelpMessage>
      ) : null}
    </div>
  );
};

export default Input;
