import { round } from "lodash";
import { addInfoToTasks } from "./calendar";
import { getYearMonth } from "./date-utils";
import { IResource, ITask, IStats, ITasksEnhanced } from "./types";

export function getStatsWithEnhancedTasks(
  enhancedTasks: ITasksEnhanced,
  now: Date
) {
  const currentYear = now.getFullYear();
  const currentYearString = `${currentYear}`;
  const previousYear = currentYear - 1;
  const previousYearString = `${previousYear}`;
  const stats: IStats = {
    total: {
      nbTasks: 0,
      meanTurnoverPerWorkedDay: 0,
      meanTurnoverPerMonth: 0,
      meanTurnoverPerYear: 0,
      meanNbDaysWorkedPerTask: 0,
      meanTurnoverPerTask: 0,
      remainingTurnover: 0,
      turnoverCurrentMonth: 0,
    },
    historic: {
      headers: [previousYearString, currentYearString],
      data: [
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
      ],
    },
  };
  if (Object.keys(enhancedTasks).length === 0) {
    return stats;
  }

  const turnoverPerYear: {
    [year: string]: [
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number
    ];
  } = {
    [previousYearString]: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [currentYearString]: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  };
  const monthsWithATask = new Set();

  let nbTasks = 0;
  let totalTurnover = 0;
  let nbDaysWorked = 0;
  let turnoverCurrentMonth = 0;
  let remainingTurnover = 0;
  let turnoverToPlan = 0;
  for (const k in enhancedTasks) {
    const task = enhancedTasks[k];
    nbTasks += 1;
    totalTurnover += task.turnover;

    if (task.turnover > 0) {
      nbDaysWorked += task.nbDaysWorked;
    }

    if (task.toPlan) {
      turnoverToPlan += task.turnover;
    }

    if (task.nbDaysWorked === 0) {
      continue;
    }

    remainingTurnover +=
      (task.turnover * task.nbDaysAfterNow) / task.nbDaysWorked;

    const currentYearMonth = getYearMonth(now);
    for (const yearMonth in task.nbDaysWorkedPerMonth) {
      const dayWorked = task.nbDaysWorkedPerMonth[yearMonth];
      monthsWithATask.add(yearMonth);
      if (yearMonth === currentYearMonth) {
        turnoverCurrentMonth += (task.turnover * dayWorked) / task.nbDaysWorked;
      }

      const yearString = yearMonth.substring(0, 4);
      const monthIndex = parseInt(yearMonth.substring(4), 10) - 1;
      if (!(yearString in turnoverPerYear)) {
        turnoverPerYear[yearString] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      }
      turnoverPerYear[yearString][monthIndex] =
        turnoverPerYear[yearString][monthIndex] +
        (task.turnover * dayWorked) / task.nbDaysWorked;
    }
  }

  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base",
  });
  const years = Object.keys(turnoverPerYear).sort(collator.compare);

  stats.historic.headers = years;
  stats.historic.data = years.reduce(
    (acc, year, i) => {
      const yearData = turnoverPerYear[year];
      for (let j = 0; j < yearData.length; j++) {
        acc[j].push(yearData[j]);
      }
      return acc;
    },
    [[], [], [], [], [], [], [], [], [], [], [], []] as number[][]
  );

  const meanTurnoverPerMonth =
    monthsWithATask.size === 0
      ? 0
      : round(totalTurnover / monthsWithATask.size);

  stats.total.nbTasks = nbTasks;
  if (nbDaysWorked !== 0) {
    stats.total.meanTurnoverPerWorkedDay = round(totalTurnover / nbDaysWorked);
  }
  stats.total.meanTurnoverPerMonth = meanTurnoverPerMonth;
  stats.total.meanTurnoverPerYear = meanTurnoverPerMonth * 12;

  if (nbTasks !== 0) {
    stats.total.meanNbDaysWorkedPerTask = round(nbDaysWorked / nbTasks);
    stats.total.meanTurnoverPerTask = round(totalTurnover / nbTasks);
  }
  stats.total.remainingTurnover = round(remainingTurnover + turnoverToPlan);
  stats.total.turnoverCurrentMonth = round(turnoverCurrentMonth);

  return stats;
}

export function getStats(
  tasks: { [taskId: string]: ITask },
  resources: { [resourceId: string]: IResource },
  nowISO?: string | undefined
) {
  const now = nowISO ? new Date(nowISO) : new Date();
  const enhancedTasks = addInfoToTasks(tasks, resources, now);
  return getStatsWithEnhancedTasks(enhancedTasks, now);
}

export function getMonthInfo(
  tasks: { [taskId: string]: ITask },
  resources: { [resourceId: string]: IResource },
  month: Date
) {
  const enhancedTasks = addInfoToTasks(tasks, resources, month);
  const monthKey = getYearMonth(month);
  const monthTasks = [];
  for (const k in enhancedTasks) {
    const task = enhancedTasks[k];
    if (monthKey in task.nbDaysWorkedPerMonth && task.nbDaysWorked > 0) {
      const monthTurnover = round(
        (task.turnover * task.nbDaysWorkedPerMonth[monthKey]) /
          task.nbDaysWorked
      );
      monthTasks.push({
        id: task.id,
        name: task.name,
        turnover: task.turnover,
        monthTurnover,
        color:task.color
      });
    }
  }
  return monthTasks;
}
