import { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useAuth } from "../auth/authContext";
import logo from "../images/logo-no-bg.png";

const CurrentLink = ({ className, ...props }) => {
  const defaultStyle =
    "px-3 py-2 rounded-md text-sm font-medium focus:outline-none focus:text-white focus:bg-gray-700";
  return (
    <NavLink
      {...props}
      activeClassName={`${defaultStyle} text-white bg-gray-900`}
      className={`${defaultStyle} text-gray-300 hover:text-white hover:bg-gray-700 ${className}`}
    />
  );
};

const Avatar = ({ children }) => {
  return (
    <span className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-400">
      <span className="text-sm font-medium leading-none text-white">
        {children}
      </span>
    </span>
  );
};

export default function Header() {
  const history = useHistory();
  const { currentUser, signOut } = useAuth();
  const { company } = currentUser;
  const [isOpen, setIsOpen] = useState(false);
  if (!currentUser || !company) return null;
  return (
    <nav className="bg-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-24">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <img className="h-20 w-20" src={logo} alt="logo" />
            </div>
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline">
                <CurrentLink to="/calendar">Emploi du temps</CurrentLink>
                {currentUser.is_reader ? null : (
                  <>
                    <CurrentLink to="/tasks" className="ml-4">
                      Chantiers
                    </CurrentLink>
                    <CurrentLink to="/resources" className="ml-4">
                      Salariés
                    </CurrentLink>
                    <CurrentLink to="/radar" className="ml-4">
                      Tableau de bord
                    </CurrentLink>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="hidden md:block">
            <div className="ml-4 flex items-center md:ml-6">
              <div className="ml-3 relative">
                <div>
                  <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="max-w-xs flex items-center text-sm rounded-full text-white focus:outline-none"
                  >
                    <Avatar>{company.name.charAt(0).toUpperCase()}</Avatar>
                  </button>
                </div>
                {isOpen && (
                  <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg">
                    <div className="py-1 rounded-md bg-white shadow-xs">
                      <NavLink
                        to="/my_account"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      >
                        Mon compte
                      </NavLink>
                      <button
                        onClick={() => {
                          signOut();
                          history.push("/");
                        }}
                        className="w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 text-left"
                      >
                        Se déconnecter
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
            >
              <svg
                className="h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  className={`${isOpen ? "hidden" : "inline-flex"}`}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
                <path
                  className={`${isOpen ? "inline-flex" : "hidden"}`}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className={`${isOpen ? "block" : "hidden"} md:hidden`}>
        <div className="px-2 pt-2 pb-3 sm:px-3">
          <CurrentLink to="/calendar" className="block">
            Planning
          </CurrentLink>
          {currentUser.is_reader ? null : (
            <>
              <CurrentLink to="/tasks" className="mt-1 block">
                Chantiers
              </CurrentLink>
              <CurrentLink to="/resources" className="mt-1 block">
                Salariés
              </CurrentLink>
              <CurrentLink to="/radar" className="mt-1 block">
                Tableau de bord
              </CurrentLink>
            </>
          )}
        </div>
        <div className="pt-4 pb-3 border-t border-gray-700">
          <div className="flex items-center px-5">
            <div className="flex-shrink-0">
              <Avatar>{company.name.charAt(0).toUpperCase()}</Avatar>
            </div>
            <div className="ml-3">
              <div className="text-base font-medium leading-none text-white">
                {company.name}
              </div>
              <div className="mt-1 text-sm font-medium leading-none text-gray-400">
                {currentUser.email}
              </div>
            </div>
          </div>
          <div className="mt-3 px-2">
            <NavLink
              to="/my_account"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
            >
              Mon compte
            </NavLink>
            <button
              onClick={() => {
                signOut();
                history.push("/");
              }}
              className="mt-1 w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
            >
              Se déconnecter
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}
