import axios from "axios";
import { getTokens } from "./localStore";

const baseURL =
  process.env.REACT_APP_BACKEND_HOST || "https://api.pilotermapme.fr/api";

axios.defaults.baseURL = baseURL;

axios.interceptors.request.use((config) => {
  const { accessToken } = getTokens();
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

export default axios;
