import { Route, Switch, Redirect } from "react-router-dom";
import Alerts from "./alerts/Alerts";
import CalendarPage from "./calendar/CalendarPage";
import TasksPage from "./tasks/TasksPage";
import ResourcesPage from "./resources/ResourcesPage";
import RadarPage from "./radar/RadarPage";
import AuthPage from "./auth/AuthPage";
import RegisterPage from "./auth/RegisterPage";
import LoginPage from "./auth/LoginPage";
import ForgotPasswordPage from "./auth/ForgotPasswordPage";
import PasswordResetPage from "./auth/PasswordResetPage";
import HomePage from "./HomePage";
import CGUCGV from "./CGUCGV";
import Page404 from "./Page404";
import MyAccountPage from "./myaccount/MyAccountPage";
import PageEmploiDuTemps from "./EmploiDuTemps/PageEmploiDuTemps";
import PageCalculCoutChantier from "./PageCalculCoutChantier"
import { useAuth } from "./auth/authContext";

const WithAuthentication = ({ children }) => {
  const { currentUser } = useAuth();
  return currentUser ? children : <Redirect to="/auth/login" />;
};

function App() {
  return (
    <div id="App">
      <Alerts />
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route exact path="/auth">
          <AuthPage />
        </Route>
        <Route exact path="/auth/signin">
          <RegisterPage />
        </Route>
        <Route path="/auth/login">
          <LoginPage />
        </Route>
        <Route path="/auth/forgotPassword">
          <ForgotPasswordPage />
        </Route>
        <Route path="/cgucgv">
          <CGUCGV />
        </Route>
        <Route path="/auth/resetPassword/:uid/:token">
          <PasswordResetPage />
        </Route>
        <Route path="/calendar">
          <WithAuthentication>
            <CalendarPage />
          </WithAuthentication>
        </Route>
        <Route path="/emploi-du-temps">
          <WithAuthentication>
            <PageEmploiDuTemps />
          </WithAuthentication>
        </Route>
        <Route path="/tasks">
          <WithAuthentication>
            <TasksPage />
          </WithAuthentication>
        </Route>
        <Route path="/resources">
          <WithAuthentication>
            <ResourcesPage />
          </WithAuthentication>
        </Route>
        <Route path="/radar">
          <WithAuthentication>
            <RadarPage />
          </WithAuthentication>
        </Route>
        <Route path="/my_account">
          <WithAuthentication>
            <MyAccountPage />
          </WithAuthentication>
        </Route>
        <Route path="/calcul-cout-chantier">
          <PageCalculCoutChantier />
        </Route>
        <Route path="*">
          <Page404 />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
