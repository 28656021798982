import * as types from "./resourcesActionsTypes";

export function setResource(resource) {
  return {
    type: types.SET_RESOURCE,
    resource,
  };
}

export function deleteResource(resource) {
  return {
    type: types.DELETE_RESOURCE,
    resource,
  };
}

export function setResources(resources) {
  return {
    type: types.SET_RESOURCES,
    resources,
  };
}
