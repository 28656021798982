import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { months } from "./months";
import { IStats } from "../core/types";
import graphColors from "../ui/graphColors";

export default function GraphTurnoverCumulated({ stats }: { stats: IStats }) {
  const data = stats.historic.data.reduce(
    (acc, h, i) => {
      const dl: { [k: string]: string | number } = { month: months[i] };
      for (let k = 0; k < h.length; k++) {
        const d = h[k];
        const year = stats.historic.headers[k];
        if (!(year in acc.total)) {
          acc.total[year] = 0;
        }
        acc.total[year] += d;
        dl[year] = Math.round(acc.total[year]);
      }
      acc.data.push(dl);
      return acc;
    },
    { total: {}, data: [] } as {
      total: { [year: string]: number };
      data: { [k: string]: string | number }[];
    }
  ).data;
  return (
    <ResponsiveContainer height={300}>
      <LineChart data={data}>
        <XAxis
          dataKey="month"
          height={70}
          interval={0}
          angle={-45}
          textAnchor="end"
        />
        <YAxis />
        <Tooltip />
        <CartesianGrid stroke="#bdddda" strokeDasharray="5 5" />
        <Legend />
        {stats.historic.headers.map((year, i) => (
          <Line
            key={i}
            name={year}
            type="monotone"
            dataKey={year}
            stroke={graphColors[i % graphColors.length]}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
}
