import { Card } from "./ui";
import { Link } from "react-router-dom";

const Page404 = () => (
  <div className="bg-gray-100 h-screen flex justify-center items-center">
    <Card className="max-w-md p-6 text-center">
      <p>
        Nous sommes désolés, mais nous n'avons pas trouvé la page que vous
        cherchez!
      </p>
      <br />
      <Link to="/">Allez à l'accueil</Link>
    </Card>
  </div>
);

export default Page404;
