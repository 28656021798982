import React from "react";
import { IStats } from "../core/types";
import { Card } from "../ui";

function Stat({
  title,
  value,
}: {
  title: string;
  value: string | React.ReactNode;
}) {
  return (
    <div className="p-6 text-center">
      <p className="text-3xl leading-none font-extrabold text-indigo-600 whitespace-no-wrap">
        {value}
      </p>
      <p className="mt-2 text-lg leading-6 font-medium text-gray-500">
        {title}
      </p>
    </div>
  );
}

export default function Indicators({ stats }: { stats: IStats }) {
  return (
    <Card
      className="p-8 flex flex-col justify-around"
      style={{ height: "100%" }}
    >
      <div className="flex flex-col md:flex-row justify-between">
        <Stat
          value={`${stats.total.meanTurnoverPerWorkedDay} €`}
          title="CA par jour"
        />
        <Stat
          value={`${stats.total.meanTurnoverPerMonth} €`}
          title="CA par mois"
        />
        <Stat
          value={`${stats.total.meanTurnoverPerYear} €`}
          title="CA par an"
        />
      </div>
      <div className="flex flex-col md:flex-row justify-between">
        <Stat value={stats.total.nbTasks} title="nb de chantiers" />
        <Stat
          value={`${stats.total.meanNbDaysWorkedPerTask} jours`}
          title="nb jours moyen par chantier"
        />
        <Stat
          value={`${stats.total.meanTurnoverPerTask}€`}
          title="CA moyen par chantier"
        />
      </div>
    </Card>
  );
}
