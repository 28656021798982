import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { hideAlert } from "./alertsActions";

export const Alert = ({ alert, hideAlert }) => {
  const color = alert.type === "success" ? "green" : "red";
  return (
    <div className={`rounded-md bg-${color}-50 p-4`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <svg
            className={`h-5 w-5 text-${color}-400`}
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="ml-3">
          <h3 className={`text-sm leading-5 font-medium text-${color}-800`}>
            {alert.title}
          </h3>
          <div className={`mt-2 text-sm leading-5 text-${color}-700`}>
            {alert.message}
          </div>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              className={`inline-flex rounded-md p-1.5 text-${color}-500 hover:bg-${color}-100 focus:outline-none focus:bg-${color}-100 transition ease-in-out duration-150`}
              onClick={hideAlert}
            >
              <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export function Alerts({ alerts, hideAlert }) {
  const alertsArray = Object.values(alerts);
  if (isEmpty(alertsArray)) return null;
  return (
    <div
      className="fixed p-2 bg-transparent top-0 right-0"
      style={{
        width: "80%",
        maxWidth: "1024px",
        zIndex: 100,
      }}
      role="alert"
    >
      {alertsArray.map((alert) => (
        <Alert
          key={alert.id}
          alert={alert}
          hideAlert={() => hideAlert(alert)}
        />
      ))}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    alerts: state.alerts,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    hideAlert: (alert) => {
      dispatch(hideAlert(alert));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
