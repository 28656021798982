import * as types from "./planningActionsTypes";

const initialState = null;

export default function reduce(state = initialState, action) {
  switch (action.type) {
    case types.SET_PLANNING:
      return {
        ...action.planning,
      };
    default:
      return state;
  }
}
