import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import alertsReducer from "./alerts/alertsReducer";
import loadingReducer from "./loading/loadingReducer";
import planningReducer from "./planning/planningReducer";
import tasksReducer from "./tasks/tasksReducer";
import resourcesReducer from "./resources/resourcesReducer";
import taskReducer from "./task/taskReducer";


export const rootReducer =combineReducers({
  alerts: alertsReducer,
  isLoading: loadingReducer,
  planning: planningReducer,
  tasks: tasksReducer,
  resources: resourcesReducer,
  task: taskReducer,
})

export type RootState = ReturnType<typeof rootReducer>

const store = createStore(
  rootReducer,
  applyMiddleware(thunk)
);

export default store;
