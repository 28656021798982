import { Link, useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { showSuccess, showError } from "../alerts/alertsActions";
import { Input } from "../ui";
import LoginBox from "./LoginBox";
import { useAuth } from "./authContext";

export const ForgotPasswordSchema = Yup.object({
  email: Yup.string()
    .email("Veuillez entrer un email valide")
    .required("Votre email est obligatoire"),
});

const ForgotPasswordForm = ({ id, onSubmit, className }) => {
  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={ForgotPasswordSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <form
          id={id}
          className={className}
          onSubmit={handleSubmit}
          aria-label="form"
        >
          <Input
            id="login_form-email"
            data-testid="login_form-email"
            label="Email"
            placeholder="Votre email"
            name="email"
            className="mb-4"
          />
        </form>
      )}
    </Formik>
  );
};

const ForgotPasswordPage = () => {
  const history = useHistory();
  const { resetPassword } = useAuth();
  const dispatch = useDispatch();

  return (
    <LoginBox>
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h1 className="mt-2 mb-8 font-semibold text-xl text-gray-900">
          Réinitializer votre mot de passe
        </h1>

        <ForgotPasswordForm
          id="forgot_password_form"
          onSubmit={(values) => {
            const { email } = values;
            resetPassword(email)
              .then(() => {
                dispatch(
                  showSuccess(
                    "Lien de confirmation envoyé avec succès",
                    `Un lien pour changer votre mot de passe a été envoyé à ${email}`
                  )
                );
                history.push("/auth/login");
              })
              .catch((error) => {
                let title = "Impossible de changer votre mot de passe";
                let message = error.message;
                if (error.code === "auth/user-not-found") {
                  title = "Email inconnu";
                  message =
                    "Cet utilisateur n'existe pas. Vous pouvez créer un compte si vous le souhaitez";
                }
                dispatch(showError(title, message));
              });
          }}
        />
        <div className="flex items-center mt-6 mb-4">
          <button
            type="submit"
            form="forgot_password_form"
            className="btn btn-primary"
          >
            Envoyer
          </button>
          <Link to="/auth/login" className="btn btn-secondary ml-2">
            Annuler
          </Link>
        </div>
      </div>
    </LoginBox>
  );
};

export default ForgotPasswordPage;
