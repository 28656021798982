import { round } from "lodash";
import {
  ITask,
  IResource,
  ICalendar,
  ITasksIdsPerDayPerEmployee,
  ITasksEnhanced,
  ITaskEnhanced,
} from "./types";
import { is_working_day, getYearMonth, getYearMonthDay } from "./date-utils";
import { getStatsWithEnhancedTasks } from "./stats";

export function addInfoToTasks(
  tasks: { [taskId: string]: ITask },
  resources: { [resourceId: string]: IResource },
  now: Date
) {
  const enhancedTasks: ITasksEnhanced = {};

  const tasksIdsPerDayPerEmployee: ITasksIdsPerDayPerEmployee = {};
  for (const index in tasks) {
    const task: ITaskEnhanced = {
      ...tasks[index],
      nbDays: 0,
      nbDaysWorked: 0,
      nbDaysWorkedWeighted: 0,
      nbDaysAfterNow: 0,
      firstDay: null,
      lastDay: null,
      nbDaysWorkedPerMonth: {},
      toPlan: true,
      segments: tasks[index].segments.map((s) => ({
        ...s,
        resourceCoefficient: 1,
        nbDays: 0,
        color: tasks[index].color,
        turnover: tasks[index].turnover,
        turnoverPerDay: 0,
        name: tasks[index].name,
        toPlan: s.resourceId === null,
      })),
    };
    for (const segment of task.segments) {
      const resourceId = segment.resourceId;
      const coefficient =
        resourceId && resourceId in resources
          ? resources[resourceId].coefficient
          : 1;
      segment.resourceCoefficient = coefficient;

      const segmentEnd = new Date(segment.end);
      for (
        var date = new Date(segment.start);
        date <= segmentEnd;
        date.setDate(date.getDate() + 1)
      ) {
        task.nbDays += 1;
        segment.nbDays += 1;
        if (task.firstDay === null || date < task.firstDay) {
          task.firstDay = date;
        }
        if (task.lastDay === null || date > task.lastDay) {
          task.lastDay = date;
        }
        if (resourceId === null) {
          continue;
        }
        task.toPlan = false;
        if (task.weekEndWorked || is_working_day(date)) {
          // For the turnoverCurrentMonth in stats
          const year_month = getYearMonth(date);
          task.nbDaysWorkedPerMonth[year_month] =
            (task.nbDaysWorkedPerMonth[year_month] || 0) + 1;

          // For the remainingTurnover
          if (date >= now) {
            task.nbDaysAfterNow += 1;
          }

          // For the duplicates of segment per day
          const l = `${getYearMonthDay(date)}${resourceId}`;
          if (!(l in tasksIdsPerDayPerEmployee)) {
            tasksIdsPerDayPerEmployee[l] = {};
          }
          if (!(task.id in tasksIdsPerDayPerEmployee[l])) {
            tasksIdsPerDayPerEmployee[l][task.id] = 0;
          }
          tasksIdsPerDayPerEmployee[l][task.id] += 1;
          const task_ids = Object.keys(tasksIdsPerDayPerEmployee[l]);
          const nb_segments_same_day = task_ids.length;
          if (nb_segments_same_day) {
            for (const task_id of task_ids) {
              const segment_duration_to_remove =
                (1 - 1 / nb_segments_same_day) * coefficient;
              if (task_id === task.id) {
                task.nbDaysWorkedWeighted -= segment_duration_to_remove;
              } else {
                enhancedTasks[task_id].nbDaysWorkedWeighted -=
                  segment_duration_to_remove;
              }
            }
          }
          if (tasksIdsPerDayPerEmployee[l][task.id] === 1) {
            task.nbDaysWorked += 1;
            task.nbDaysWorkedWeighted += coefficient;
          }
        }
      }
    }
    enhancedTasks[task.id] = task;
  }
  return enhancedTasks;
}

export function compute(
  tasks: { [taskId: string]: ITask },
  resources: { [resourceId: string]: IResource },
  nowISO: string | undefined
) {
  const now = nowISO ? new Date(nowISO) : new Date();
  const calendar: ICalendar = {
    segmentsPerResource: {
      toplan: {
        id: null,
        name: "A Planifier",
        disabled: false,
        segments: [],
      },
    },
    stats: getStatsWithEnhancedTasks({}, now),
  };
  for (const resourceId in resources) {
    const resource = resources[resourceId];
    calendar.segmentsPerResource[resourceId] = {
      id: resourceId,
      name: resource.name,
      disabled: resource.disabled,
      segments: [],
    };
  }
  if (Object.keys(tasks).length === 0 || Object.keys(resources).length === 0) {
    return calendar;
  }

  const enhancedTasks = addInfoToTasks(tasks, resources, now);

  for (const k in enhancedTasks) {
    const task = enhancedTasks[k];

    const turnoverPerDay = task.nbDaysWorkedWeighted
      ? task.turnover / task.nbDaysWorkedWeighted
      : 0;
    for (const segment of task.segments) {
      segment.turnoverPerDay = round(
        turnoverPerDay * segment.resourceCoefficient
      );
      calendar.segmentsPerResource[
        segment.resourceId || "toplan"
      ].segments.push(segment);
    }
  }
  calendar.stats = getStatsWithEnhancedTasks(enhancedTasks, now);
  return calendar;
}
