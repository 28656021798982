import { useEffect, useState } from "react";
import { Header, Loader, Modal, Euro, ConfirmDeleteModal } from "../ui";
import { useSearch } from "../hooks";
import { FaEuroSign } from "react-icons/fa";
import { DateTime } from "luxon";
import { setResources } from "../resources/resourcesActions";
import { removeTask, setTasks } from "./tasksActions";
import { useDispatch, useSelector } from "react-redux";
import { deleteTask, getResources, getTasks } from "../services/api";

function TaskDetails({ task, resources }) {
  if (!task || !resources) return null;
  return (
    <>
      <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {task.name}
        </h3>
      </div>
      <div className="">
        <dl>
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
            <dt className="text-sm leading-5 font-medium text-gray-500">
              Chiffre d'affaire
            </dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              <Euro value={task.turnover} />
            </dd>
          </div>
          <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
            <dt className="text-sm leading-5 font-medium text-gray-500">
              Périodes
            </dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              <ul className="border border-gray-200 rounded-md">
                {task.segments.map((s, i) => {
                  const start = DateTime.fromISO(s.start);
                  const end = DateTime.fromISO(s.end);
                  return (
                    <li
                      className="pl-3 pr-4 py-3 flex flex-col sm:flex-row items-center justify-between text-sm leading-5"
                      key={i}
                    >
                      <div className="w-full sm:w-1/2">
                        <DurationText
                          start={start}
                          end={end}
                          className="mr-1.5"
                        />
                        {`(${
                          end.diff(start, ["months", "days"]).toObject()["days"]
                        }j)`}
                      </div>
                      <div className="w-full sm:w-1/6">
                        {s.resourceId
                          ? resources[s.resourceId].name
                          : "A planifier"}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </dd>
          </div>
        </dl>
      </div>
    </>
  );
}

const DurationText = ({ start, end, ...props }) => (
  <span {...props}>
    <span className="mr-1">Du</span>
    <time datetime={start.toISO()}>
      {start.toLocaleString(DateTime.DATE_MED)}
    </time>
    <span className="mx-1">Au</span>
    <time datetime={end.toISO()}>{end.toLocaleString(DateTime.DATE_MED)}</time>
  </span>
);

const TasksPage = () => {
  const [task, setTask] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const tasks = useSelector((state) => Object.values(state.tasks));
  const resources = useSelector((state) => state.resources);

  useEffect(() => {
    Promise.all([getTasks(), getResources()])
      .then((results) => {
        dispatch(setTasks(results[0]));
        dispatch(setResources(results[1]));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch]);

  const { results, search, searchTerm } = useSearch({
    data: tasks,
    options: {
      keys: ["name", "name_without_accent"],
    },
  });
  return (
    <div>
      <Header />
      <main className="p-4">
        {isLoading ? (
          <div className="w-full py-32 flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <div>
            <Modal isOpen={task !== null} close={() => setTask(null)}>
              <TaskDetails task={task} resources={resources} />
            </Modal>

            <div className="bg-white shadow overflow-hidden ">
              <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                <div className="-ml-4 -mt-2 flex items-center flex-wrap sm:flex-no-wrap">
                  <div className="ml-4 mt-2">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Chantiers
                    </h3>
                  </div>
                  <div className="ml-10 mt-2 flex-shrink-0">
                    <div className="relative rounded-md shadow-md">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <svg
                          className="h-5 w-5 text-gray-400"
                          fill="currentColor"
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                        >
                          <path d="M16.604 15.868l-5.173-5.173c0.975-1.137 1.569-2.611 1.569-4.223 0-3.584-2.916-6.5-6.5-6.5-1.736 0-3.369 0.676-4.598 1.903-1.227 1.228-1.903 2.861-1.902 4.597 0 3.584 2.916 6.5 6.5 6.5 1.612 0 3.087-0.594 4.224-1.569l5.173 5.173 0.707-0.708zM6.5 11.972c-3.032 0-5.5-2.467-5.5-5.5-0.001-1.47 0.571-2.851 1.61-3.889 1.038-1.039 2.42-1.611 3.89-1.611 3.032 0 5.5 2.467 5.5 5.5 0 3.032-2.468 5.5-5.5 5.5z" />
                        </svg>
                      </div>
                      <input
                        id="email"
                        className="form-input block w-full pl-10 sm:text-sm sm:leading-5"
                        placeholder="Rechercher un chantier"
                        value={searchTerm}
                        onChange={(e) => search(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="overflow-hidden">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Nom
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Chiffre d'affaire
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Nb interventions
                            </th>
                            <th scope="col" className="relative px-6 py-3">
                              <span className="sr-only">Edit</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {results.map((task, i) => (
                            <tr key={i}>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {task.name}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                <span className="px-2 inline-flex items-center text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                  <FaEuroSign className="icon" />
                                  {task.turnover}
                                </span>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {task.segments.length}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setTask(task);
                                  }}
                                  href="#"
                                  className="text-indigo-600 hover:text-indigo-900"
                                >
                                  voir
                                </button>
                                <ConfirmDeleteModal
                                  title="Supprimer un chantier"
                                  confirmTextButton={`Oui supprimer ${task.name}!`}
                                  message={
                                    <p>
                                      Etes vous sur de vouloir supprimer{" "}
                                      {task.name}?
                                      <br />
                                      L'ensemble des interventions associées à
                                      ce chantier seront{" "}
                                      <span className="text-red-800 font-bold">
                                        supprimés
                                      </span>
                                      .
                                    </p>
                                  }
                                  confirmed={() => {
                                    dispatch(removeTask(task));
                                    deleteTask(task);
                                  }}
                                  button={(toggle) => (
                                    <button
                                      className="text-red-600 hover:text-red-900 ml-4"
                                      onClick={toggle}
                                    >
                                      <span className="hidden sm:block">
                                        supprimer
                                      </span>
                                    </button>
                                  )}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default TasksPage;
