import * as types from "./resourcesActionsTypes";

const initialState = {};

export default function reduce(state = initialState, action) {
  switch (action.type) {
    case types.SET_RESOURCE:
      return {
        ...state,
        [action.resource.id]: action.resource,
      };
    case types.DELETE_RESOURCE:
      delete state[action.resource.id];
      return {
        ...state,
      };
    case types.SET_RESOURCES:
      return action.resources.reduce(
        (acc, resource) => {
          acc[resource.id] = resource;
          return acc;
        },
        { ...state }
      );
    default:
      return state;
  }
}
