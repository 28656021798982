import {
  createContext,
  ReactNode,
  useState,
  useContext,
  useEffect,
} from "react";
import * as api from "../services/api";
import LoadingPage from "../LoadingPage";
import { ICurrentUser, IRegisterProps, IUserProps } from "../types";

type AuthContextProps = {
  status: string;
  currentUser: ICurrentUser | null;
  login: (user: IUserProps) => Promise<ICurrentUser>;
  refreshCurrentUser: () => Promise<void>;
  register: (info: IRegisterProps) => Promise<ICurrentUser>;
  signOut: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  confirmResetPassword: (
    uid: string,
    token: string,
    password: string
  ) => Promise<void>;
};

const AuthContext = createContext({} as AuthContextProps);

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, setState] = useState<{
    status: string;
    currentUser: ICurrentUser | null;
  }>({
    status: "pending",
    currentUser: null,
  });

  useEffect(() => {
    api.getCurrentUser().then(
      (currentUser: ICurrentUser) => setState({ currentUser, status: "success" }),
      () => setState({ currentUser: null, status: "error" })
    );
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login: (user: IUserProps) => {
          return api
            .createToken(user)
            .then(api.saveTokens)
            .then(api.getCurrentUser)
            .then((currentUser) => {
              setState({ ...state, currentUser });
              return currentUser;
            });
        },
        refreshCurrentUser: () => {
          return api.getCurrentUser().then((currentUser: ICurrentUser) => {
            setState({ ...state, currentUser });
          });
        },
        register: (info: IRegisterProps) => {
          return api
            .removeTokens()
            .then(() => api.register(info))
            .then(() => api.createToken(info))
            .then((tokens) => api.saveTokens(tokens))
            .then(api.getCurrentUser)
            .then((currentUser: ICurrentUser) => {
              setState({ ...state, currentUser });
              return currentUser;
            });
        },
        signOut: () => {
          setState({ ...state, currentUser: null });
          return api.removeTokens();
        },
        resetPassword: api.resetPassword,
        confirmResetPassword: api.confirmResetPassword,
      }}
    >
      {state.status === "pending" ? <LoadingPage /> : children}
    </AuthContext.Provider>
  );
}

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
