import { findIndex } from "lodash";
import { sortByOrder } from "../services/sort";

function move(resources, resource, direction) {
  const resourcesSorted = sortByOrder(resources);
  const resourceIndex = findIndex(resourcesSorted, (e) => e.id === resource.id);
  const newResourceIndex = resourceIndex + 1 * direction;
  const extractedResource = resourcesSorted.splice(resourceIndex, 1)[0];
  resourcesSorted.splice(newResourceIndex, 0, extractedResource);
  return resourcesSorted.map((item, i) => ({ ...item, order: i + 1 }));
}

export function moveResourceUp(resources, resource) {
  return move(resources, resource, -1);
}

export function moveResourceDown(resources, resource) {
  return move(resources, resource, +1);
}
