import { useDispatch } from "react-redux";
import { Card, Header } from "../ui";
import * as api from "../services/api";
import { DateTime, Interval } from "luxon";
import { showSuccess, showError } from "../alerts/alertsActions";
import { useHistory } from "react-router";
import { useAuth } from "../auth/authContext";
import CompanyForm from "./CompanyForm";
import ChangePasswordForm from "./ChangePasswordForm";

const CompanySection = ({ company }) => {
  const dispatch = useDispatch();
  const { refreshCurrentUser } = useAuth();
  return (
    <div>
      <CompanyForm
        company={company}
        onSubmit={(updatedCompany) => {
          api
            .updateCompany(updatedCompany)
            .then(() => {
              dispatch(
                showSuccess(
                  "Entreprise mise à jour",
                  "Votre entreprise a été mise à jour"
                )
              );
            })
            .catch(() => {
              dispatch(
                showError(
                  "Une erreur est survenue",
                  "Il nous est impossible de sauvegarder votre entreprise pour le moment. Veuillez retentez dans quelques minutes."
                )
              );
            })
            .finally(refreshCurrentUser);
        }}
        className="mb-4"
      />
      <div className="flex items-center justify-between mb-6">
        <button type="submit" form="company_form" className="btn btn-primary">
          Mettre à jour
        </button>
      </div>
    </div>
  );
};

const ChangePasswordSection = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { signOut } = useAuth();
  return (
    <div>
      <ChangePasswordForm
        onSubmit={(values) => {
          api
            .setPassword(values)
            .then(() => {
              dispatch(
                showSuccess(
                  "Mot de passe mise à jour",
                  "Votre mot de passe a été mis à jour correctement. Veuillez vous reconnecter avec votre nouveau mot de passe."
                )
              );
              signOut();
              history.push("/auth/login");
            })
            .catch((error) => {
              if (error.response.status === 400) {
                const errors = Object.values(error.response.data);
                for (let index = 0; index < errors.length; index++) {
                  const error = errors[index];
                  dispatch(showError("Une erreur est survenue", error));
                }
              } else {
                dispatch(
                  showError(
                    "Une erreur est survenue",
                    "Il nous est impossible de changer votre mot de passe pour le moment. Veuillez retenter dans quelques minutes"
                  )
                );
              }
            });
        }}
      />
      <div className="flex items-center justify-between mb-6">
        <button
          type="submit"
          form="change_password_form"
          className="btn btn-primary"
        >
          Mettre à jour
        </button>
      </div>
    </div>
  );
};

function nbDaysBeforeEndOfTry(companyCreatedAt) {
  const trialDays = 30;
  const nbDaysSinceCompanyCreated = Interval.fromDateTimes(
    DateTime.fromISO(companyCreatedAt),
    DateTime.local()
  ).count("days");
  const diff = trialDays - nbDaysSinceCompanyCreated;
  if (diff < 0) return 0;
  return diff;
}

const ActiveSubscription = () => {
  return (
    <div>
      <p>
        Votre abonnement est actif. Pour annuler votre abonnement, envoyez nous
        un email en cliquant sur le{" "}
        <a
          href="mailto:contact@pilotermapme.fr?subject=Annuler mon abonnement"
          className="text-reed-500"
        >
          lien suivant
        </a>
      </p>
    </div>
  );
};

const TrialingSubscription = ({ nbDaysRemaining }) => {
  return (
    <p>
      Il vous reste{" "}
      <span className="bg-indigo-500 text-white py-1 px-2 font-bold">
        {nbDaysRemaining}
      </span>{" "}
      jour{nbDaysRemaining === 1 ? "" : "s"} avant la fin de votre période
      d'essai.
    </p>
  );
};

const Coupon = ({ coupon }) => {
  return (
    <p>
      Vous bénéfissiez d'une réduction de{" "}
      <span className="bg-indigo-500 text-white py-1 px-2 font-bold">
        {Math.round(coupon.amount_off / 100)}€
      </span>{" "}
      chaque mois. Libéllé sur votre facture: <b>{coupon.name}</b>
    </p>
  );
};

const Plan = ({ plan }) => {
  return (
    <p>
      Vous avez souscrit à l'abonnement <b>{plan.name}</b> pour un montant de{" "}
      <span className="bg-indigo-500 text-white py-1 px-2 font-bold">
        {plan.amount / 100}€
      </span>{" "}
      chaque mois.
    </p>
  );
};

const SubscriptionSection = ({ currentUser }) => {
  const subscription = currentUser.subscription;
  const nbDaysRemaining = nbDaysBeforeEndOfTry(currentUser.company.created);
  return (
    <div>
      {subscription.status === "active" && <ActiveSubscription />}
      {subscription.status === "trialing" && (
        <TrialingSubscription nbDaysRemaining={nbDaysRemaining} />
      )}
      <Plan plan={subscription.plan} />
      {subscription.coupon && <Coupon coupon={subscription.coupon} />}
    </div>
  );
};

const MyAccountPage = () => {
  const currentUser = useAuth().currentUser;
  return (
    <div>
      <Header />
      <main className="p-4">
        <Card className="p-10 mb-5">
          <h2 className="h2">
            Bienvenue {currentUser.name || currentUser.email}
          </h2>
          Vous pouvez mettre à jour vos informations personnelles.
        </Card>
        <div className="flex flex-col md:flex-row md:-mx-4">
          <div className="w-full md:w-1/2 md:px-4">
            <Card className="p-10 mb-5">
              <h2 className="h2">Mon entreprise</h2>
              <CompanySection company={currentUser.company} />
            </Card>
          </div>
          <div className="w-full md:w-1/2 md:px-4">
            <Card className="p-10 mb-5">
              <h2 className="h2">Changer mon mot de passe</h2>
              <ChangePasswordSection />
            </Card>
          </div>
        </div>
        <Card className="p-10">
          <div className="flex md:-mx-4">
            <div className="w-full md:px-4">
              <h2 className="h2">Abonnement</h2>
              <SubscriptionSection currentUser={currentUser} />
            </div>
          </div>
        </Card>
      </main>
    </div>
  );
};

export default MyAccountPage;
