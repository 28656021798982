import * as types from "./tasksActionsTypes";

export function setTask(task) {
  return {
    type: types.SET_TASK,
    task,
  };
}

export function removeTask(task) {
  return {
    type: types.REMOVE_TASK,
    task,
  };
}

export function setTasks(tasks) {
  return {
    type: types.SET_TASKS,
    tasks,
  };
}

export function updateSegmentInTask(segment) {
  return {
    type: types.UPDATE_SEGMENT,
    segment,
  };
}

export function deleteDocumentInTask(document, task) {
  return {
    type: types.DOCUMENT_DELETED,
    task,
    document,
  };
}
