import { DateTime } from "luxon";
import * as types from "./taskActionsTypes";
import { colors } from "../ui";

export function showTaskModal(task) {
  return {
    type: types.SHOW_TASK_MODAL,
    task,
  };
}

export function openTaskForSegment(segment) {
  return (dispatch, getState) => {
    const { tasks } = getState();
    const task = tasks[segment.taskId];
    const taskWithSegmentSelected = {
      ...task,
      segments: task.segments.map((s) => {
        if (s.id === segment.id) {
          return {
            ...s,
            selected: true,
          };
        }
        return {
          ...s,
          selected: false,
        };
      }),
    };
    dispatch(showTaskModal(taskWithSegmentSelected));
  };
}

export function createAnEmptyTask(now = DateTime.local(), resourceId = null) {
  const colorsName = Object.keys(colors);
  const randomColor = colorsName[Math.floor(Math.random() * colorsName.length)];
  const nbDays = 5;
  const fiveDaysAfter = now.plus({ days: nbDays - 1 });
  return {
    name: "",
    turnover: 0,
    weekEndWorked: false,
    color: randomColor,
    documents: [],
    segments: [
      {
        start: now.toISODate(),
        end: fiveDaysAfter.toISODate(),
        resourceId,
        selected: true,
      },
    ],
  };
}

export function showSegment(segment) {
  return (dispatch, getState) => {
    const task = getState().tasks[segment.taskId];
    return dispatch(
      showTaskModal({
        ...task,
        segments: task.segments.map((s) => {
          if (s.id === segment.id) {
            s.selected = true;
            return s;
          }
          return s;
        }),
      })
    );
  };
}

export function hideTaskModal() {
  return {
    type: types.HIDE_TASK_MODAL,
  };
}
