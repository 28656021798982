import { Link, useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { showSuccess, showError } from "../alerts/alertsActions";
import { Input } from "../ui";
import LoginBox from "./LoginBox";
import { useAuth } from "./authContext";

export const PasswordResetConfirmSchema = Yup.object({
  password: Yup.string()
    .min(8, "Votre nouveau mot de passe est trop court")
    .required("Votre nouveau mot de passe est obligatoire"),
});

const PasswordResetConfirmForm = ({ id, onSubmit, className }) => {
  return (
    <Formik
      initialValues={{ password: "" }}
      validationSchema={PasswordResetConfirmSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <form
          id={id}
          className={className}
          onSubmit={handleSubmit}
          aria-label="form"
        >
          <Input
            id="login_form-password"
            data-testid="login_form-password"
            label="Nouveau mot de passe"
            placeholder="Votre nouveau mot de passe"
            name="password"
            type="password"
          />
        </form>
      )}
    </Formik>
  );
};

const PasswordResetPage = ({ match }) => {
  const { uid, token } = match.params;
  const history = useHistory();
  const { confirmResetPassword } = useAuth();
  const dispatch = useDispatch();
  return (
    <LoginBox>
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h1 className="mt-2 mb-8 font-semibold text-xl text-gray-900">
          Changer de mot de passe
        </h1>

        <PasswordResetConfirmForm
          id="password_reset_form"
          onSubmit={(values) => {
            const { password } = values;
            confirmResetPassword(uid, token, password)
              .then(() => {
                dispatch(
                  showSuccess(
                    "Votre mot de passe a été changé avec success",
                    "Vous pouvez maintenant vous connecter."
                  )
                );
                history.push("/auth/login");
              })
              .catch(() => {
                dispatch(
                  showError(
                    "Impossible de changer votre mot de passe",
                    "Veuillez retentez dans quelques minutes"
                  )
                );
              });
          }}
        />
        <div className="flex items-center mt-6 mb-4">
          <button
            type="submit"
            form="password_reset_form"
            className="btn btn-primary"
          >
            Changer de mot de passe
          </button>
          <Link to="/auth/login" className="btn btn-secondary ml-2">
            Annuler
          </Link>
        </div>
      </div>
    </LoginBox>
  );
};

export default PasswordResetPage;
