import { Formik } from "formik";
import * as Yup from "yup";
import { Input } from "../ui";

export const RegisterSchema = Yup.object({
  company: Yup.string()
    .min(2, "Le nom de votre entreprise est trop court")
    .required("Le nom de votre entreprise est obligatoire"),
  email: Yup.string()
    .email("Veuillez entrer un email valide")
    .required("Votre email est obligatoire"),
  password: Yup.string()
    .min(8, "Votre mot de passe est trop court")
    .required("Votre mot de passe est obligatoire"),
});

const RegisterForm = ({ id, onSubmit, className }) => {
  return (
    <Formik
      initialValues={{ company: "", email: "", password: "" }}
      validationSchema={RegisterSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <form
          id={id}
          className={className}
          onSubmit={handleSubmit}
          aria-label="form"
        >
          <Input
            id="register_form-company"
            data-testid="register_form-company"
            label="Entreprise"
            placeholder="Votre entreprise"
            name="company"
            type="text"
            className="mb-4"
          />
          <Input
            id="register_form-email"
            data-testid="register_form-email"
            label="Email"
            placeholder="Votre email"
            name="email"
            type="email"
            className="mb-4"
          />
          <Input
            id="register_form-password"
            data-testid="register_form-password"
            label="Mot de passe"
            placeholder="Votre mot de passe"
            name="password"
            type="password"
            className="mb-4"
          />
        </form>
      )}
    </Formik>
  );
};

export default RegisterForm;
