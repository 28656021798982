import "react-app-polyfill/ie9";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Settings } from "luxon";
import store from "./store";
import { AuthProvider } from "./auth/authContext";
import "react-datepicker/dist/react-datepicker.css";
import "./styles/react-resizable.css";
import "./index.css";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";

registerLocale("fr", fr);

Settings.defaultLocale = "fr";

ReactDOM.render(
  <Provider store={store}>
    <AuthProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AuthProvider>
  </Provider>,
  document.getElementById("root")
);
