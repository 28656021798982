import { Link } from "react-router-dom";
import logo from "../images/logo.png";
import { Card } from "../ui";

export default function LoginBox({ children }) {
  return (
    <div className="w-full h-screen flex flex-col px-2 lg:px-6">
      <div className="w-full max-w-md mx-auto mt-8 lg:mt-32">
        <div className="flex justify-center h-16">
          <Link to="/" className="h1 no-underline">
            <img src={logo} alt="logo" className="h-24" />
          </Link>
        </div>
        <Card>{children}</Card>
      </div>
    </div>
  );
}
