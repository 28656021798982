import * as types from "./loadingActionsTypes";

const initialState = true;

export default function reduce(state = initialState, action) {
  switch (action.type) {
    case types.LOADING_STARTED:
      return true;
    case types.LOADING_STOPPED:
      return false;
    default:
      return state;
  }
}
