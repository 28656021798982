import NumberFormat from "react-number-format";

const Euro = ({ value, suffix = " €" }) => (
  <NumberFormat
    value={value || 0}
    displayType="text"
    thousandSeparator=" "
    decimalScale={0}
    suffix={suffix}
  />
);

export default Euro;
