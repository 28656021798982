export const accessTokenKey = "accessToken";
export const refreshTokenKey = "refreshToken";

export function saveTokens(tokens) {
  localStorage.setItem(accessTokenKey, tokens.accessToken);
  localStorage.setItem(refreshTokenKey, tokens.refreshToken);
  return Promise.resolve(tokens);
}

export function removeTokens() {
  localStorage.removeItem(accessTokenKey);
  localStorage.removeItem(refreshTokenKey);
  return Promise.resolve();
}

export function getTokens() {
  return {
    accessToken: localStorage.getItem(accessTokenKey),
    refreshToken: localStorage.getItem(refreshTokenKey),
  };
}
