import Modal from "./Modal";
import { useModal } from "../hooks";

const ConfirmDeleteModal = ({
  title,
  message,
  confirmTextButton = "Oui",
  cancelTextButton = "Non",
  confirmed,
  button,
}) => {
  const { isOpen, toggle } = useModal(false);
  return (
    <>
      <Modal
        title={title}
        isOpen={isOpen}
        close={toggle}
        actionsRight={[
          <button
            key="confirmDelete"
            className="btn btn-danger"
            onClick={() => {
              toggle();
              confirmed();
            }}
          >
            {confirmTextButton}
          </button>,
          <button
            key="cancelDelete"
            type="button"
            className="btn btn-primary ml-2"
            onClick={toggle}
          >
            {cancelTextButton}
          </button>,
        ]}
      >
        {message}
      </Modal>
      {button(toggle)}
    </>
  );
};

export default ConfirmDeleteModal;
