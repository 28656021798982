import { useEffect } from "react";
import { Header } from "../ui";
import Calendar from "./Calendar";
import { useDimensions } from "../hooks";
import { useDispatch, useSelector } from "react-redux";
import {
  moveSegment,
  resizeSegment,
  duplicateASegment,
} from "../planning/planningActions";
import {
  showTaskModal,
  createAnEmptyTask,
  openTaskForSegment,
} from "../task/taskActions";
import {
  setTask,
  removeTask,
  setTasks,
  updateSegmentInTask,
} from "../tasks/tasksActions";
import { compute } from "../core/calendar";
import {
  createTask,
  deleteTask,
  getResources,
  getTasks,
  updateSegment,
  updateTask,
} from "../services/api";
import { setResources } from "../resources/resourcesActions";
import { DashboardHeader } from "./DashboardHeader";
import { useAuth } from "../auth/authContext";

function CalendarPage() {
  const { currentUser } = useAuth();
  const [ref, size] = useDimensions();
  const { width } = size;
  const dispatch = useDispatch();
  const task = useSelector((state) => state.task);
  const calendar = useSelector((state) =>
    compute(state.tasks, state.resources)
  );
  useEffect(() => {
    getTasks().then((tasks) => dispatch(setTasks(tasks)));
    getResources().then((resources) => dispatch(setResources(resources)));
  }, [dispatch]);
  return (
    <div>
      <Header />
      <main ref={ref} className="p-4">
        {currentUser.is_reader ? null : (
          <DashboardHeader stats={calendar.stats} />
        )}
        {width ? (
          <Calendar
            segmentsPerResource={calendar.segmentsPerResource}
            width={width}
            enableKeyboardEvent={task === null}
            createANewTask={(newTask) => {
              createTask(newTask).then((t) => dispatch(setTask(t)));
            }}
            updateATask={(newTask) => {
              updateTask(newTask).then((t) => dispatch(setTask(t)));
            }}
            deleteATask={() => {
              dispatch(removeTask(task));
              deleteTask(task);
            }}
            showTaskModal={() => {
              dispatch(showTaskModal(createAnEmptyTask()));
            }}
            openTaskAtIndex={(date, resourceId) => {
              dispatch(showTaskModal(createAnEmptyTask(date, resourceId)));
            }}
            openTaskForSegment={(segment) => {
              dispatch(openTaskForSegment(segment));
            }}
            duplicateASegment={(segment) => {
              dispatch(duplicateASegment(segment)).then((newTask) => {
                updateTask(newTask).then((t) => dispatch(setTask(t)));
              });
            }}
            moveSegment={(segment, toResourceId, newStart) => {
              const newSegment = moveSegment(
                calendar.segmentsPerResource,
                segment,
                toResourceId,
                newStart
              );
              dispatch(updateSegmentInTask(newSegment));
              updateSegment(newSegment);
            }}
            resizeSegment={(segment, nbDays) => {
              const newSegment = resizeSegment(
                calendar.segmentsPerResource,
                segment,
                nbDays
              );
              dispatch(updateSegmentInTask(newSegment));
              updateSegment(newSegment);
            }}
          />
        ) : null}
      </main>
    </div>
  );
}

export default CalendarPage;
