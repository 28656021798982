import * as types from "./alertsActionsTypes";

export function showAlert(alert) {
  return {
    type: types.SHOW_ALERT,
    alert,
  };
}

export function hideAlert(alert) {
  return {
    type: types.HIDE_ALERT,
    alert,
  };
}

function showAndHideAfter10s(title = "", message = "", type = "success") {
  const alert = {
    id: Date.now(),
    title,
    message,
    type,
  };
  return (dispatch) => {
    dispatch(showAlert(alert));
    setTimeout(() => dispatch(hideAlert(alert)), 10000);
  };
}

export function showSuccess(title, message) {
  return showAndHideAfter10s(title, message, "success");
}

export function showError(title, message) {
  return showAndHideAfter10s(title, message, "danger");
}
