import { Card } from "./ui";
import logo from "./images/logo.png";

const CGUCGV = () => {
  return (
    <div className="bg-gray-100 py-20">
      <Card className="max-w-4xl mx-auto w-full p-6">
        <img src={logo} alt="logo" className="mb-12 w-32 mx-auto" />
        <h1 className="h1">Conditions Générales de Vente et d’Utilisation</h1>
        <h2 className="h2 mt-12">1. A propos de nous</h2>
        <p>
          La Société FOXWARE, SAS, au capital de 1 000 euros, dont le siège
          social est à PESSAC (33600) 8 Rue des Bouleaux, immatriculée au
          registre du commerce et des sociétés de BORDEAUX sous le numéro 881
          144 505 représentée par Monsieur Thomas PEREZ (ci-après la "Société”).
          La Société propose les services suivants : Logiciel de gestion de
          planning et de chiffre d'affaire pour les PME du BTP.
        </p>
        <h2 className="h2 mt-12">2. Préambule</h2>
        <p>
          La Société invite ses Utilisateurs à lire attentivement les présentes
          Conditions Générales de Vente et d’Utilisation (ci-après les
          "CGV/CGU"). Les CGV/CGU s'appliquent à tous les Services fournis par
          la Société à ses Clients d'une même catégorie, quelles que soient les
          clauses pouvant être inscrites sur les documents du Client et
          notamment ses conditions générales d'achat.
        </p>
        <p>
          Les CGV/CGU sont systématiquement communiquées au Client qui en fait
          la demande.
        </p>
        <p>
          Le Client est tenu de prendre connaissance des CGV/CGU avant toute
          passation de Commande.
        </p>
        <p>
          En cas de modification ultérieure des CGV/CGU, le Client est soumis à
          la version en vigueur lors de sa Commande.
        </p>
        <p>
          Les données enregistrées dans le système informatique de la Société
          constituent la preuve des transactions conclues avec le Client.
        </p>
        <h2 className="h2 mt-12">3. Définitions</h2>
        <p>
          <ul className="list-disc pl-8">
            <li>
              <b>Client</b> désigne toute personne physique ou morale qui passe
              une Commande sur le présent Site Internet;
            </li>
            <li>
              <b>Commande</b> désigne toute commande passée par l’Utilisateur
              inscrit sur le présent Site, en vue de bénéficier des Services de
              la Société;
            </li>
            <li>
              <b>Conditions</b> Générales de Vente et d'Utilisation" ou
              "CGV/CGU" désignent les présentes conditions générales de vente et
              d'utilisation en ligne;
            </li>
            <li>
              <b>Consommateur</b> désigne l'acheteur personne physique qui
              n'agit pas pour des besoins professionnels et/ ou hors de son
              activité professionnelle;
            </li>
            <li>
              <b>Professionnel</b> désigne l'acheteur personne morale ou
              physique qui agit dans le cadre de son activité professionnelle;
            </li>
            <li>
              <b>Services</b> désigne toutes les prestations de service
              proposées aux Utilisateurs par la Société à travers le présent
              Site;
            </li>
            <li>
              <b>Site</b> désigne le présent Site, c’est-à-dire
              www.pilotermapme.fr;
            </li>
            <li>
              <b>Société</b> désigne la Société FOXWARE, plus amplement désignée
              à l'article I des présentes; et
            </li>
            <li>
              <b>Utilisateur</b> désigne toute personne qui fait utilisation du
              Site.
            </li>
          </ul>
        </p>
        <h2 className="h2 mt-12">4. Inscription</h2>

        <p>
          L’inscription au Site est ouverte à toutes les personnes morales ou
          physiques majeures et jouissant de leurs pleines personnalités et
          capacités juridiques.
        </p>
        <p>
          L’utilisation des Services proposés sur le Site est conditionnée à
          l’inscription de l’Utilisateur sur le Site. L’inscription est payante
          avec un mois d’utilisation gratuite.
        </p>
        <p>
          Pour procéder à l’inscription, l’Utilisateur doit remplir tous les
          champs obligatoires, sans lesquels le service ne peut être délivré. A
          défaut l’inscription ne pourra être complétée.
        </p>
        <p>
          Les Utilisateurs garantissent et déclarent sur l'honneur que toutes
          les informations communiquées sur le Site, notamment lors de leur
          inscription, sont exactes et conformes à la réalité. Ils s’engagent à
          mettre à jour leurs informations personnelles à partir de la page
          dédiée à ces dernières et disponible dans leur compte.
        </p>
        <p>
          Tout Utilisateur enregistré dispose d’un identifiant et d’un mot de
          passe. Ces derniers sont strictement personnels et confidentiels et ne
          devront en aucun cas faire l’objet d’une communication à des tiers
          sous peine de suppression du compte de l’Utilisateur enregistré
          contrevenant. Chaque Utilisateur inscrit est personnellement
          responsable du maintien de la confidentialité de son identifiant et de
          son mot de passe. La Société ne sera en aucun cas tenue pour
          responsable de l’usurpation d’identité d’un Utilisateur. Si un
          Utilisateur suspecte une fraude à n’importe quel moment, il devra
          contacter la Société dans les plus brefs délais afin que cette
          dernière puisse prendre les mesures nécessaires et régulariser la
          situation.
        </p>
        <p>
          Chaque Utilisateur, qu’il soit une personne morale ou physique, ne
          peut être titulaire que d’un compte sur le Site.
        </p>
        <p>
          En cas de non-respect des CGV/CGU, notamment la création de plusieurs
          comptes pour une seule personne ou encore la fourniture de fausses
          informations, la Société se réserve le droit de procéder à la
          suppression temporaire ou définitive de tous les comptes créés par
          l’Utilisateur enregistré contrevenant.
        </p>
        <p>
          La suppression du compte entraîne la perte définitive de tous les
          avantages et services acquis sur le Site. Cependant, toute Commande
          réalisée et facturée par le Site avant la suppression du compte sera
          exécutée dans les conditions normales.
        </p>
        <p>
          En cas de suppression d’un compte par la Société pour manquement aux
          devoirs et obligations énoncées dans les CGV/CGU, il est formellement
          interdit à l’Utilisateur contrevenant de se réinscrire sur le Site
          directement, par le biais d’une autre adresse électronique ou par
          personne interposée sans l’autorisation expresse de la Société.
        </p>
        <h2 className="h2 mt-12">5. Prestation de service et prix</h2>
        <p>
          Les Services faisant l’objet des CGV/CGU sont ceux qui figurent sur le
          Site et qui sont proposés directement par la Société ou ses
          prestataires partenaires.
        </p>
        <p>
          Les services sont décrits sur la page correspondante au sein du Site
          et mention est faite de toutes leurs caractéristiques essentielles. La
          Société ne peut être tenue responsable de l’impossibilité de réaliser
          ladite prestation lorsque l’Utilisateur n’est pas éligible.
        </p>
        <p>
          Lorsqu’un Utilisateur enregistré souhaite obtenir une prestation de
          service vendue par la Société à travers le Site, le prix indiqué sur
          la page de la prestation correspond au prix en euros toutes taxes
          comprise (TTC) et tient compte des réductions applicables et en
          vigueur le jour de la Commande. Le prix indiqué n’inclut pas les frais
          accessoires qui seront indiqués le cas échéant dans le récapitulatif
          avant de passer commande.
        </p>
        <p>
          La Société se réserve la possibilité de modifier à tout moment ses
          tarifs. Elle s'engage à facturer les prestations au tarif applicable
          au moment de leur Commande. Pour les prestations dont le prix ne peut
          être connu a priori ni indiqué avec certitude, un devis détaillé sera
          transmis au Client.
        </p>
        <p>
          En aucun cas un Utilisateur ne pourra exiger l’application de
          réductions n’étant plus en vigueur le jour de la Commande.
        </p>
        <h2 className="h2 mt-12">6. Commandes</h2>
        <p>
          Toute Commande ne peut être réalisée que lorsque l’Utilisateur s’est
          inscrit sur le Site. L’Utilisateur, lorsqu’il est connecté sur son
          compte ou lors de la première connexion, peut sélectionner un ou
          plusieurs Services à son panier virtuel. Il peut ensuite confirmer les
          Services qu’il souhaite commander et effectuer sa Commande en appuyant
          sur le bouton “Commander" ou “Payer”.
        </p>
        <p>
          L'enregistrement d'une Commande sur le Site est réalisé lorsque le
          Client accepte les CGV/CGU et valide sa Commande. Cette validation
          constitue une preuve du contrat de vente. La finalisation de la
          Commande implique l'acceptation des prix et des modalités de
          réalisations des prestations telles qu'indiquées sur le Site.
        </p>
        <p>
          Une fois sa Commande passée, le Client en recevra confirmation par
          courrier électronique. Cette confirmation fera le récapitulatif de la
          Commande et des informations relatives à la réalisation de la ou des
          prestations.
        </p>
        <p>
          En cas de défaut de paiement, d'adresse erronée du Client ou autre
          problème sur le compte du Client, la Société se réserve le droit de
          bloquer la commande du Client jusqu'à ce que le problème soit résolu.
          En cas d'impossibilité de réaliser la prestation, le Client en sera
          informé par courrier électronique à l'adresse qu'il aura fournie à la
          Société. L'annulation de la commande de cette prestation et son
          remboursement seront dans ce cas effectués, le reste de la commande
          demeurant ferme et définitif.
        </p>
        <p>
          La Société pourra faire bénéficier le Client de réduction de prix,
          remises et rabais en fonction du nombre des Services commandés ou en
          fonction de la régularité des Commandes, selon les conditions fixées
          par la Société.
        </p>
        <h2 className="h2 mt-12">7. Délais et modalités de paiement</h2>
        <p>
          Sauf dispositions contraires, toutes les ventes sont payées comptant
          au moment de la passation de la Commande.
        </p>
        <p>
          En fonction de la nature ou du montant de la Commande, la Société
          reste libre d'exiger un acompte ou le paiement de l'intégralité du
          prix lors de la passation de la Commande ou lors de la réception de la
          facture.
        </p>
        <p>Le paiement peut être réalisé par :</p>
        <p className="pl-8">Carte bancaire</p>
        <p>
          En cas de défaut de paiement total ou partiel des prestations à la
          date convenue sur la facture, le Client Professionnel devra verser à
          la Société une pénalité de retard dont le taux est égal au taux
          pratiqué par la Banque Centrale Européenne pour son opération de
          refinancement majoré de 10 points de pourcentage. L'opération de
          financement retenue est la plus récente à la date de la Commande des
          prestations de Service.
        </p>
        <p>
          En sus des indemnités de retard, toute somme, y compris l’acompte, non
          payée à sa date d’exigibilité produira de plein droit le paiement
          d’une indemnité forfaitaire de 40 euros due au titre des frais de
          recouvrement.
        </p>
        <p>
          En cas de défaut de paiement total ou partiel des prestations à la
          date convenue sur la facture, le Client Consommateur devra verser à la
          Société une pénalité de retard dont le taux est égal au taux d'intérêt
          légal.
        </p>
        <p>
          Aucune compensation ne pourra être effectuée par le Client entre des
          pénalités de retard dans la fourniture des prestations commandées et
          des sommes dues par le Client à la Société au titre de l'achat de
          Services proposés sur le Site.
        </p>
        <p>
          La pénalité due par le Client, Professionnel ou Consommateur, est
          calculée sur le montant toutes taxes comprises de la somme restante
          due, et court à compter de la date d'échéance du prix sans qu'aucune
          mise en demeure préalable ne soit nécessaire.
        </p>
        <p>
          En cas de non-respect des conditions de paiement décrites ci-dessus,
          la Société se réserve la possibilité d'annuler ou de suspendre la
          vente.
        </p>
        <h2 className="h2 mt-12">8. Réalisation des prestations</h2>
        <p>
          La prestation de services commandée sur le Site sera assurée par :
        </p>
        <p className="pl-8">La Société</p>
        <p>
          La Société s'engage à mettre en oeuvre tous les moyens humains et
          matériels pour réaliser la prestation dans les délais annoncés lors de
          la passation de la Commande. Cependant, elle ne peut en aucun cas être
          tenue responsable pour les retards de réalisation de prestation
          occasionnés en raison de fautes qui ne lui sont imputables.
        </p>
        <p>
          Si les prestations n'ont pas été effectuées dans le délai prévu, la
          résolution de la vente pourra être demandée par le Client dans les
          conditions prévues aux articles L216-2 et L216-3 du Code de la
          consommation. Les sommes versées par le Client lui seront restituées
          au plus tard dans les quatorze jours qui suivent la date de
          dénonciation du contrat. Cette disposition ne s'applique pas quand le
          retard de la Société est dû à une faute du Client ou à un cas de force
          majeure, c'est à dire à la survenance d'un évènement imprévisible,
          irrésistible et indépendant de la volonté de la Société.
        </p>
        <p>
          Dans le cas où l'exécution d'une prestation physique n'aurait pas pu
          être effectuée ou aurait été reportée du fait d'une erreur sur
          l'adresse indiquée par le Client, les frais de déplacement du
          prestataire mandaté par la Société pour réaliser la prestation
          infructueuse seront à la charge du Client.
        </p>
        <h2 className="h2 mt-12">9. Réclamation</h2>
        <p>
          Pour toutes les Commandes réalisées sur le présent Site, le Client
          dispose d’un droit de réclamation de 14 jours à compter de la
          fourniture du Service.
        </p>
        <p>
          Pour exercer ce droit de réclamation, le Client doit faire parvenir à
          la Société, à l'adresse contact@pilotermapme.fr, une déclaration dans
          laquelle il exprime ses réserves et réclamations, assorties des
          justificatifs y afférents.
        </p>
        <p>
          Une réclamation ne respectant pas les conditions décrites ci-dessus ne
          pourra être acceptée.
        </p>
        <p>
          Après examen de la réclamation, le Site pourra le cas échéant
          remplacer ou rembourser la prestation de Services dans les plus brefs
          délais et à ses frais.
        </p>
        <h2 className="h2 mt-12">10. Droit de rétractation du Consommateur</h2>
        <p>
          Le Consommateur dispose d’un droit de rétractation de 14 jours à
          compter de la passation de la Commande, sauf pour les produits
          mentionnés à l'article L221-28 du Code de la consommation tel que
          reproduit ci-dessous :
        </p>
        <p>
          Le droit de rétractation ne peut être exercé pour les contrats :
          <ul className="list-decimal pl-8">
            <li>
              De fourniture de services pleinement exécutés avant la fin du
              délai de rétractation et dont l'exécution a commencé après accord
              préalable exprès du consommateur et renoncement exprès à son droit
              de rétractation;
            </li>
            <li>
              De fourniture de biens ou de services dont le prix dépend de
              fluctuations sur le marché financier échappant au contrôle du
              professionnel et susceptibles de se produire pendant le délai de
              rétractation;
            </li>
            <li>
              De fourniture de biens confectionnés selon les spécifications du
              consommateur ou nettement personnalisés;
            </li>
            <li>
              De fourniture de biens susceptibles de se détériorer ou de se
              périmer rapidement;
            </li>
            <li>
              De fourniture de biens qui ont été descellés par le consommateur
              après la livraison et qui ne peuvent être renvoyés pour des
              raisons d'hygiène ou de protection de la santé;
            </li>
            <li>
              De fourniture de biens qui, après avoir été livrés et de par leur
              nature, sont mélangés de manière indissociable avec d'autres
              articles;
            </li>
            <li>
              De fourniture de boissons alcoolisées dont la livraison est
              différée au-delà de trente jours et dont la valeur convenue à la
              conclusion du contrat dépend de fluctuations sur le marché
              échappant au contrôle du professionnel;
            </li>
            <li>
              De travaux d'entretien ou de réparation à réaliser en urgence au
              domicile du consommateur et expressément sollicités par lui, dans
              la limite des pièces de rechange et travaux strictement
              nécessaires pour répondre à l'urgence;
            </li>
            <li>
              De fourniture d'enregistrements audio ou vidéo ou de logiciels
              informatiques lorsqu'ils ont été descellés par le consommateur
              après la livraison;
            </li>
            <li>
              De fourniture d'un journal, d'un périodique ou d'un magazine, sauf
              pour les contrats d'abonnement à ces publications;
            </li>
            <li> Conclus lors d'une enchère publique; </li>
            <li>
              De prestations de services d'hébergement, autres que d'hébergement
              résidentiel, de services de transport de biens, de locations de
              voitures, de restauration ou d'activités de loisirs qui doivent
              être fournis à une date ou à une période déterminée;
            </li>
            <li>
              De fourniture d'un contenu numérique non fourni sur un support
              matériel dont l'exécution a commencé après accord préalable exprès
              du consommateur et renoncement exprès à son droit de
              rétractation." Pour exercer ce droit de rétractation, le
              Consommateur envoie une déclaration à l'adresse :
              contact@pilotermapme.fr. Il sera remboursé de la totalité des
              frais versés pour la prestation de services dans les 14 jours
              suivant la prise de connaissance par la Société de sa déclaration
              de rétractation. Le remboursement sera fait par le même moyen de
              paiement que celui utilisé à l'achat. Cependant, si la prestation
              de services est déjà entamée à la date de la prise de connaissance
              de la rétractation par la Société, la valeur correspondant à la
              prestation de service déjà effectuée sera déduite du
              remboursement. Ce dernier sera opéré par le même moyen de paiement
              que pour l’achat.
            </li>
          </ul>
        </p>
        <h2 className="h2 mt-12">11. Traitement des données personnelles</h2>
        <p>
          L'enregistrement sur le Site entraîne le traitement des données à
          caractère personnel du Client. Si le Client refuse le traitement de
          ses données, il lui est demandé de s'abstenir d’utiliser le Site.{" "}
        </p>
        <p>
          Ce traitement des données à caractère personnel se fait dans le
          respect du Règlement Général sur la Protection des Données 2016/679 du
          27 avril 2016.{" "}
        </p>
        <p>
          {" "}
          Par ailleurs, conformément à la loi Informatique et Libertés du 6
          janvier 1978, le Client dispose, à tout moment, d'un droit
          d'interrogation, d'accès, de rectification, de modification et
          d'opposition à l'ensemble de ses données personnelles en écrivant, par
          courrier et en justifiant de son identité, à l'adresse suivante :
          contact@pilotermapme.fr.{" "}
        </p>
        <p>
          Ces données personnelles sont nécessaires au traitement de sa Commande
          et à l'établissement de ses factures le cas échéant, ainsi qu'à
          l'amélioration des fonctionnalités du Site.
        </p>
        <h2 className="h2 mt-12">12. Partage des données collectées</h2>
        <p>
          Le Site peut avoir recours à des sociétés tierces pour effectuer
          certaines opérations. En naviguant sur le Site, le Client accepte que
          des sociétés tierces puissent avoir accès à ses données pour permettre
          le bon fonctionnement du Site.{" "}
        </p>
        <p>
          {" "}
          Ces sociétés tierces n'ont accès aux données collectées que dans la
          cadre de la réalisation d'une tâche précise.{" "}
        </p>
        <p>Le Site reste responsable du traitement de ces données. </p>
        <p>
          Par ailleurs, l'Utilisateur peut donc être amené à recevoir des
          informations ou des offres commerciales de la part de la Société ou de
          ses partenaires.{" "}
        </p>
        <p>
          L'Utilisateur peut à tout moment s'opposer à la réception de ces
          offres commerciales, en écrivant à l'adresse de la Société indiquée
          ci-dessus, ou en cliquant sur le lien prévu à cet effet au sein des
          courriers électroniques reçus.{" "}
        </p>
        <p>
          {" "}
          Par ailleurs, les informations des Clients pourront être transmises à
          des tiers sans leur accord express préalable afin d'atteindre les buts
          suivants :{" "}
        </p>
        <p className="pl-8">
          respecter la loi protéger toute personne contre des dommages corporels
          graves, voire la mort lutter contre la fraude ou les atteintes portées
          à la Société ou à ses utilisateurs protéger les droits de propriété de
          la Société.
        </p>
        <h2 className="h2 mt-12">13. Protection des données</h2>
        <p>
          La Société assure un niveau de sécurité approprié et proportionnel aux
          risques encourus ainsi qu'à leur probabilité, conformément au
          Règlement Général sur la Protection des Données 2016/679 du 27 avril
          2016.{" "}
        </p>
        <p>
          Toutefois, ces mesures ne constituent en aucun cas une garantie et
          n'engagent pas la Société à une obligation de résultat concernant la
          sécurité des données.
        </p>
        <h2 className="h2 mt-12">14. Cookies</h2>
        <p>
          Pour permettre à ses Utilisateurs de bénéficier d’une navigation
          optimale sur le Site et d’un meilleur fonctionnement des différentes
          interfaces et applications, la Société est susceptible d’implanter un
          cookie sur l’ordinateur de l’Utilisateur. Ce cookie permet de stocker
          des informations relatives à la navigation sur le Site, ainsi qu’aux
          éventuelles données saisies par les Utilisateurs (notamment
          recherches, login, email, mot de passe).{" "}
        </p>
        <p>
          L’Utilisateur autorise expressément la Société à déposer sur le disque
          dur de l’utilisateur un fichier dit« cookie ».
        </p>
        <p>
          {" "}
          L’Utilisateur dispose de la possibilité de bloquer, modifier la durée
          de conservation, ou supprimer ce cookie via l’interface de son
          navigateur. Si la désactivation systématique des cookies sur le
          navigateur de l’Utilisateur l’empêche d’utiliser certains services ou
          fonctionnalités du Site, ce dysfonctionnement ne saurait en aucun cas
          constituer un dommage pour le membre qui ne pourra prétendre à aucune
          indemnité de ce fait.
        </p>
        <h2 className="h2 mt-12">15. Modifications</h2>
        <p>
          La Société se réserve le droit de modifier le Site, les services qui y
          sont proposés, les CGV/CGU ainsi que toute procédure de livraison ou
          autre élément constitutif des prestations réalisées par la Société par
          le biais du Site.{" "}
        </p>
        <p>
          Lorsqu'il passe une Commande, l'Utilisateur est soumis aux
          stipulations énoncées par les CGV/CGU en vigueur lors de la passation
          de la Commande.
        </p>
        <h2 className="h2 mt-12">16. Responsabilité</h2>
        <p>
          La Société ne peut en aucun cas être tenue responsable pour
          l’indisponibilité, qu’elle soit temporaire ou permanente du Site
          Internet et, bien qu’elle mette en œuvre tous les moyens afin
          d’assurer en permanence le service, il se peut que celui-ci soit
          interrompu à tout moment. En outre, la Société se réserve le droit,
          par un acte volontaire, de rendre le Site indisponible afin de
          réaliser toute opération de mise à jour, d’amélioration ou de
          maintenance.
        </p>
        <p>
          {" "}
          Tel que mentionné auparavant dans les présentes, la Société ne peut en
          aucun cas être tenue responsable des retards de réalisation d’une
          prestation de service pour des motifs qui échappent à son contrôle,
          indépendants de sa volonté, imprévisibles et irrésistibles ou dont la
          faute ne peut lui être imputable.
        </p>
        <h2 className="h2 mt-12">17. Propriété intellectuelle</h2>
        <p>
          La marque, le logo, et la charte graphique du présent Site sont des
          oeuvres protégées au titre de la propriété intellectuelle, dont la
          propriété revient exclusivement à la Société. Toute diffusion,
          exploitation, représentation, reproduction, qu’elle soit partielle ou
          intégrale sans l’autorisation expresse de ladite société exposera le
          contrevenant à des poursuites civiles et pénales.
        </p>
        <h2 className="h2 mt-12">18. Clause attributive de compétence</h2>
        <p>
          Le droit régissant les CGV/CGU est le droit français. Tout litige
          pouvant survenir entre la Société et un Utilisateur lors de
          l’exécution des présentes fera l’objet d’une tentative de résolution à
          l’amiable. A défaut, les litiges seront portés à la connaissance des
          tribunaux compétents de droit commun.
        </p>
        <p>
          {" "}
          Le Client est informé qu'il peut recourir à une médiation
          conventionnelle, auprès de la Commission de la médiation de la
          Consommation prévue à l'article L534-7 du Code de la consommation ou
          auprès des instances de médiation sectorielles existantes. Il peut
          également recourir à tout mode alternatif de règlement des différends
          en cas de litige.
        </p>
        <h2 className="h2 mt-12">19. Information précontractuelle</h2>
        <p>
          Préalablement à sa Commande, le Client reconnaît avoir eu
          communication, de façon lisible et compréhensible, des CGV/CGU et des
          informations et renseignements prévus aux articles L111-1 à L111-7 du
          Code de la consommation, et notamment :{" "}
        </p>
        <p className="pl-8">
          les caractéristiques essentielles des Services ; le prix des Services
          ; la date ou le délai auquel la Société s'engage à fournir le Service
          ; les informations relatives à l'identité de la Société (coordonnées
          postales, téléphoniques, électroniques) ; les informations relatives
          aux garanties légales et contractuelles et à leurs modalités de mise
          en oeuvre ; la possibilité de recourir à une médiation conventionnelle
          en cas de litige ; les informations relatives au droit de rétractation
          (délai, modalités d'exercice).
        </p>
        <p>
          {" "}
          La passation d'une Commande sur le Site emporte adhésion et
          acceptation des CGV/CGU. Le Client ne pourra se prévaloir d'un
          document contradictoire.
        </p>
        <h2 className="h2 mt-12">20. Coordonnées du médiateur</h2>
        <p>Medicys 73 boulevard de Clichy, PARIS 75009 </p>
      </Card>
    </div>
  );
};

export default CGUCGV;
