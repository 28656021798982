export default function Modal({
  title,
  isOpen,
  close,
  actionsLeft = [],
  actionsRight = [],
  children,
  size = "5xl",
}) {
  if (!isOpen) return null;
  return (
    <div className="z-20 fixed w-full h-full top-0 left-0 flex items-center justify-center">
      <div
        className="z-30 absolute w-full h-full bg-gray-900 opacity-50"
        onClick={close}
      ></div>

      <div
        className={`z-40 bg-white w-11/12 lg:max-w-${size} mx-auto rounded shadow-lg block`}
        style={{ maxHeight: "90vh", overflowY: "auto", overflowX: "hidden" }}
      >
        <div className="z-40 py-4 text-left px-6">
          <div className="z-40 flex justify-between items-center pb-3">
            <h1 className="h1 mb-0">{title}</h1>
            <div className="z-40 cursor-pointer" onClick={close}>
              <svg
                className="icon mr-2"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
              </svg>
            </div>
          </div>
          {children}
          <div className="flex justify-between pt-2">
            <div>{actionsLeft.map((action) => action)}</div>
            <div>{actionsRight.map((action) => action)}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
