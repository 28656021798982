import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  BarChart,
  Bar,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { IStats } from "../core/types";
import graphColors from "../ui/graphColors";
import { months } from "./months";

export default function GraphTurnover({ stats }: { stats: IStats }) {
  const data = stats.historic.data.reduce((acc, h, i) => {
    const d: { [k: string]: string | number } = { month: months[i] };
    for (let j = 0; j < h.length; j++) {
      const year = stats.historic.headers[j];
      d[year] = Math.round(h[j]);
    }
    acc.push(d);
    return acc;
  }, [] as { [k: string]: string | number }[]);
  console.log(data);
  return (
    <div>
      <ResponsiveContainer height={300}>
        <BarChart data={data}>
          <XAxis
            dataKey="month"
            height={70}
            interval={0}
            angle={-45}
            textAnchor="end"
          />
          <YAxis />
          <Tooltip />
          <CartesianGrid stroke="#bdddda" strokeDasharray="5 5" />
          <Legend />
          {stats.historic.headers.map((year, i) => (
            <Bar
              key={i}
              name={year}
              dataKey={year}
              fill={graphColors[i % graphColors.length]}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
