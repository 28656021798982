const colors = {
  lightGray: {
    name: "lightGray",
    textColor: "text-gray-800",
    backgroundColor: "bg-gray-200",
    hue: 200,
  },
  gray: {
    name: "gray",
    textColor: "text-gray-800",
    backgroundColor: "bg-gray-500",
    hue: 500,
  },
  grayDark: {
    name: "grayDark",
    textColor: "text-white",
    backgroundColor: "bg-gray-900",
    hue: 900,
  },
  lightRed: {
    name: "lightRed",
    textColor: "text-gray-800",
    backgroundColor: "bg-red-200",
    hue: 200,
  },
  red: {
    name: "red",
    textColor: "text-white",
    backgroundColor: "bg-red-500",
    hue: 500,
  },
  darkRed: {
    name: "darkRed",
    textColor: "text-white",
    backgroundColor: "bg-red-900",
    hue: 900,
  },
  lightOrange: {
    name: "lightOrange",
    textColor: "text-gray-800",
    backgroundColor: "bg-orange-200",
    hue: 200,
  },
  orange: {
    name: "orange",
    textColor: "text-gray-800",
    backgroundColor: "bg-orange-500",
    hue: 500,
  },
  darkOrange: {
    name: "darkOrange",
    textColor: "text-white",
    backgroundColor: "bg-orange-900",
    hue: 900,
  },
  lightYellow: {
    name: "lightYellow",
    textColor: "text-gray-800",
    backgroundColor: "bg-yellow-200",
    hue: 200,
  },
  yellow: {
    name: "yellow",
    textColor: "text-gray-800",
    backgroundColor: "bg-yellow-500",
    hue: 500,
  },
  darkYellow: {
    name: "darkYellow",
    textColor: "text-white",
    backgroundColor: "bg-yellow-900",
    hue: 900,
  },
  lightGreen: {
    name: "lightGreen",
    textColor: "text-gray-800",
    backgroundColor: "bg-green-200",
    hue: 200,
  },
  green: {
    name: "green",
    textColor: "text-gray-800",
    backgroundColor: "bg-green-500",
    hue: 500,
  },
  darkGreen: {
    name: "darkGreen",
    textColor: "text-white",
    backgroundColor: "bg-green-900",
    hue: 900,
  },
  lightTeal: {
    name: "lightTeal",
    textColor: "text-gray-800",
    backgroundColor: "bg-teal-200",
    hue: 200,
  },
  teal: {
    name: "teal",
    textColor: "text-white",
    backgroundColor: "bg-teal-500",
    hue: 500,
  },
  darkTeal: {
    name: "darkTeal",
    textColor: "text-white",
    backgroundColor: "bg-teal-900",
    hue: 900,
  },
  lightBlue: {
    name: "lightBlue",
    textColor: "text-gray-800",
    backgroundColor: "bg-blue-200",
    hue: 200,
  },
  blue: {
    name: "blue",
    textColor: "text-white",
    backgroundColor: "bg-blue-500",
    hue: 500,
  },
  darkBlue: {
    name: "darkBlue",
    textColor: "text-white",
    backgroundColor: "bg-blue-900",
    hue: 900,
  },
  lightIndigo: {
    name: "lightIndigo",
    textColor: "text-gray-800",
    backgroundColor: "bg-indigo-200",
    hue: 200,
  },
  indigo: {
    name: "indigo",
    textColor: "text-white",
    backgroundColor: "bg-indigo-500",
    hue: 500,
  },
  darkIndigo: {
    name: "darkIndigo",
    textColor: "text-white",
    backgroundColor: "bg-indigo-900",
    hue: 900,
  },
  lightPurple: {
    name: "lightPurple",
    textColor: "text-gray-800",
    backgroundColor: "bg-purple-200",
    hue: 200,
  },
  purple: {
    name: "purple",
    textColor: "text-white",
    backgroundColor: "bg-purple-500",
    hue: 500,
  },
  darkPurple: {
    name: "darkPurple",
    textColor: "text-white",
    backgroundColor: "bg-purple-900",
    hue: 900,
  },
  lightPink: {
    name: "lightPink",
    textColor: "text-gray-800",
    backgroundColor: "bg-pink-200",
    hue: 200,
  },
  pink: {
    name: "pink",
    textColor: "text-white",
    backgroundColor: "bg-pink-500",
    hue: 500,
  },
  darkPink: {
    name: "darkPink",
    textColor: "text-white",
    backgroundColor: "bg-pink-900",
    hue: 900,
  },
};

export default colors;
