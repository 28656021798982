import { useField, useFormikContext } from "formik";
import Label from "./Label";
import Select from "react-select";
import HelpMessage from "./HelpMessage";

export const SelectField = ({
  id,
  label,
  help,
  options,
  className,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  return (
    <div className={className}>
      <Label htmlFor={props.id || props.name}>{label}</Label>
      <Select
        {...field}
        {...props}
        classNamePrefix={id}
        className="mt-1"
        inputId={id}
        options={options}
        value={
          options ? options.find((option) => option.value === field.value) : ""
        }
        onChange={(option) => setFieldValue(field.name, option.value)}
      />
      {help ? <HelpMessage>{help}</HelpMessage> : null}
    </div>
  );
};

export default SelectField;
