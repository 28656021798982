import { isEmpty } from "lodash";
import * as order from "./resourcesOrder";
import * as api from "../services/api";
import { Header, Card, Modal, ConfirmDeleteModal, Loader } from "../ui";
import ResourceForm from "./ResourceForm";
import {
  FaEdit,
  FaEyeSlash,
  FaEye,
  FaArrowUp,
  FaArrowDown,
  FaTrashAlt,
  FaPlus,
} from "react-icons/fa";
import { useModal } from "../hooks";
import { useCallback, useEffect, useState } from "react";

const EditResourceModal = ({ resource, onSubmit, button }) => {
  const { isOpen, toggle } = useModal(false);
  return (
    <>
      <Modal
        title={`Editer le salarié ${resource.name}`}
        isOpen={isOpen}
        close={toggle}
        actionsRight={[
          <button
            key="cancelEditResourceButton"
            type="button"
            className="btn btn-secondary mr-2"
            onClick={toggle}
          >
            Annuler
          </button>,
          <button
            key="editResourceButton"
            form="edit_resource_form"
            type="submit"
            className="btn btn-primary"
          >
            Editer
          </button>,
        ]}
      >
        <ResourceForm
          id="edit_resource_form"
          resource={resource}
          onSubmit={(e) => {
            toggle();
            onSubmit(e);
          }}
        />
      </Modal>
      {button(toggle)}
    </>
  );
};

const ResourcesTable = ({
  resources,
  moveResourceUp,
  moveResourceDown,
  editResource,
}) => {
  return (
    <table className="min-w-full">
      <tbody>
        <tr>
          <th className="w-1/6 text-center">Ordre</th>
          <th>Nom</th>
          <th className="text-center">Coefficient de productivité</th>
          <th className="text-center">Actions</th>
        </tr>
        {isEmpty(resources) && (
          <tr>
            <td colSpan={4}>
              Vous n'avez pas de salariés enregistrés. Créez un salarié pour
              commencer à utiliser Piloter Ma PME.
            </td>
          </tr>
        )}
        {resources.map((resource, i) => (
          <tr key={i}>
            <td className="text-center">
              <div className="flex flex-col md:flex-row justify-center items-center">
                <button
                  className={`btn btn-primary btn-icon btn-sm ${
                    i === 0 ? "btn-disabled" : ""
                  } mr-0 md:mr-2 mb-2 md:mb-0`}
                  onClick={() => moveResourceUp(resource)}
                  disabled={i === 0}
                >
                  <FaArrowUp className="icon" />
                </button>
                <button
                  className={`btn btn-primary btn-icon btn-sm ${
                    i === resources.length - 1 ? "btn-disabled" : ""
                  } mr-0 md:mr-2`}
                  onClick={() => moveResourceDown(resource)}
                  disabled={i === resources.length - 1}
                >
                  <FaArrowDown className="icon" />
                </button>
              </div>
            </td>
            <td className="px-4 py-2">{resource.name}</td>
            <td className="px-4 py-2 text-center">{resource.coefficient}</td>
            <td className="px-4 py-2 text-right">
              <div className="flex flex-col md:flex-row justify-center items-center">
                <EditResourceModal
                  resource={resource}
                  onSubmit={editResource}
                  button={(toggle) => (
                    <button
                      className="btn btn-primary btn-icon btn-sm mb-2 md:mb-0 mr-0 md:mr-2"
                      onClick={toggle}
                    >
                      <FaEdit className="icon mr-0 sm:mr-2" />
                      <span className="hidden sm:block">éditer</span>
                    </button>
                  )}
                />

                <button
                  className="btn btn-danger btn-icon btn-sm"
                  onClick={() =>
                    editResource({
                      ...resource,
                      disabled: true,
                    })
                  }
                >
                  <FaEyeSlash className="icon mr-0 sm:mr-2" />
                  <span className="hidden sm:block">désactiver</span>
                </button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const DisabledResourcesTable = ({
  resources,
  editResource,
  deleteResource,
}) => {
  return (
    <table className="min-w-full">
      <tbody>
        <tr>
          <th className="w-1/3">Nom</th>
          <th className="w-1/3 text-center">Coefficient de productivité</th>
          <th className="w-1/3 text-center">Actions</th>
        </tr>
        {isEmpty(resources) && (
          <tr>
            <td colSpan={4}>Vous n'avez pas de salariés désactivés.</td>
          </tr>
        )}
        {resources.map((resource, i) => (
          <tr key={i}>
            <td className="px-4 py-2">{resource.name}</td>
            <td className="px-4 py-2 text-center">{resource.coefficient}</td>
            <td className="flex justify-center px-4 py-2 text-right">
              <div className="flex flex-col md:flex-row justify-center items-center">
                <button
                  className="btn btn-success btn-icon btn-sm mb-2 md:mb-0 mr-0 md:mr-2"
                  onClick={() =>
                    editResource({
                      ...resource,
                      disabled: false,
                    })
                  }
                >
                  <FaEye className="icon mr-0 sm:mr-2" />
                  <span className="hidden sm:block">activer</span>
                </button>
                <ConfirmDeleteModal
                  title="Supprimer un salarié"
                  confirmTextButton={`Oui supprimer ${resource.name}!`}
                  message={
                    <p>
                      Etes vous sur de vouloir supprimer {resource.name}?
                      <br />
                      L'ensemble des chantiers associés à cet employé seront{" "}
                      <span className="text-red-800 font-bold">supprimés</span>.
                    </p>
                  }
                  confirmed={() => deleteResource(resource)}
                  button={(toggle) => (
                    <button
                      className="btn btn-danger btn-icon btn-sm"
                      onClick={toggle}
                    >
                      <FaTrashAlt className="icon mr-0 sm:mr-2" />
                      <span className="hidden sm:block">supprimer</span>
                    </button>
                  )}
                />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default function ResourcesPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [resources, setResources] = useState([]);

  const _getResourcesCallback = useCallback(() => {
    api
      .getResources()
      .then((resources) => setResources(resources))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    _getResourcesCallback();
  }, [_getResourcesCallback]);

  return (
    <div>
      <Header />
      <main className="p-4">
        {isLoading ? (
          <div className="w-full py-32 flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <div className="flex flex-col lg:flex-row -mx-2">
            <div className="w-full lg:w-1/3 px-2">
              <Card className="p-8 mb-4">
                <h2 className="h2">Créer un salarié</h2>
                <ResourceForm
                  id="create_resource_form"
                  resource={{
                    name: "",
                    coefficient: 1,
                    order: 0,
                    disabled: false,
                  }}
                  onSubmit={(resource) => {
                    api.createResource(resource).then(_getResourcesCallback);
                  }}
                />
                <button
                  type="submit"
                  form="create_resource_form"
                  className="btn btn-primary btn-icon mt-4"
                >
                  <FaPlus className="icon mr-2" />
                  Créer un salarié
                </button>
              </Card>
            </div>
            <div className="w-full lg:w-2/3 px-2">
              <Card className="p-8">
                <h2 className="h2">Salariés</h2>
                <div className="mb-8">
                  <ResourcesTable
                    resources={resources.filter((r) => !r.disabled)}
                    moveResourceUp={(resource) => {
                      const resourcesSorted = order.moveResourceUp(
                        resources,
                        resource
                      );
                      const promises = [];
                      for (let i = 0; i < resourcesSorted.length; i++) {
                        promises.push(api.updateResource(resourcesSorted[i]));
                      }
                      Promise.all(promises).then(_getResourcesCallback);
                    }}
                    moveResourceDown={(resource) => {
                      const resourcesSorted = order.moveResourceDown(
                        resources,
                        resource
                      );
                      const promises = [];
                      for (let i = 0; i < resourcesSorted.length; i++) {
                        promises.push(api.updateResource(resourcesSorted[i]));
                      }
                      Promise.all(promises).then(_getResourcesCallback);
                    }}
                    editResource={(resource) => {
                      api.updateResource(resource).then(_getResourcesCallback);
                    }}
                  />
                </div>
                <h2 className="h2">Salariés désactivés</h2>
                <p>
                  Les salariés désactivés n'apparaissent plus dans l'emploi du
                  temps.
                </p>
                <DisabledResourcesTable
                  resources={resources.filter((r) => r.disabled)}
                  editResource={(resource) => {
                    api.updateResource(resource).then(_getResourcesCallback);
                  }}
                  deleteResource={(resource) => {
                    api.deleteResource(resource);
                  }}
                />
              </Card>
            </div>
          </div>
        )}
      </main>
    </div>
  );
}
