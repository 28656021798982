const Label = ({ children, className, ...props }) => (
  <label
    className={`block text-sm font-medium leading-5 text-gray-700 ${className}`}
    {...props}
  >
    {children}
  </label>
);

export default Label;
