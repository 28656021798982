import { useHistory, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showError } from "../alerts/alertsActions";
import LoginBox from "./LoginBox";
import { useAuth } from "./authContext";
import LoginForm from "./LoginForm";

const LoginPage = () => {
  const history = useHistory();
  const { login } = useAuth();
  const dispatch = useDispatch();

  return (
    <LoginBox>
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h1 className="my-2 font-semibold text-xl text-gray-900">
          Se connecter
        </h1>
        <p className="mb-8">
          ou
          <Link to="/auth/signin" className="no-underline ml-2">
            créez un compte gratuitement
          </Link>
        </p>
        <LoginForm
          id="login_form"
          onSubmit={(credentials) => {
            login(credentials)
              .then(() => history.push("/calendar"))
              .catch(() => {
                let title = "Impossible de se connecter";
                let message =
                  "Les informations renseignés sont invalides ou l'utilisateur n'existe pas. Si vous n'avez pas encore de compte, vous pouvez en créer un.";
                dispatch(showError(title, message));
              });
          }}
        />
        <div className="flex items-center justify-end text-sm -mt-2">
          <Link to="/auth/forgotPassword" className="no-underline">
            Mot de passe oublié?
          </Link>
        </div>
        <div className="flex items-center justify-between mt-4 mb-6">
          <button type="submit" form="login_form" className="btn btn-primary">
            Se connecter
          </button>
        </div>
      </div>
    </LoginBox>
  );
};

export default LoginPage;
