import { ITimestampCache } from "./types";

export function getYearMonth(date: Date) {
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  const year = d.getFullYear();
  if (month.length < 2) month = "0" + month;
  return `${year}${month}`;
}
export function getYearMonthDay(date: Date) {
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  const year = d.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return `${year}${month}${day}`;
}

function _easter(year: number) {
  var f = Math.floor,
    // Golden Number - 1
    G = year % 19,
    C = f(year / 100),
    // related to Epact
    H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30,
    // number of days from 21 March to the Paschal full moon
    I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11)),
    // weekday for the Paschal full moon
    J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7,
    // number of days from 21 March to the Sunday on or before the Paschal full moon
    L = I - J,
    month = 3 + f((L + 40) / 44),
    day = L + 28 - 31 * f(month / 4);

  return new Date(year, month - 1, day);
}

const cache: ITimestampCache = {};

function _get_public_holidays_in_year(year: number) {
  if (year in cache) {
    return cache[year];
  }
  const jour_de_lan = new Date(year, 0, 1);
  const fete_du_travail = new Date(year, 4, 1);
  const victoire_des_allies = new Date(year, 4, 8);
  const fete_nationale = new Date(year, 6, 14);
  const assomption = new Date(year, 7, 15);
  const toussaint = new Date(year, 10, 1);
  const armistice = new Date(year, 10, 11);
  const noel = new Date(year, 11, 25);
  const paques = _easter(year);
  const lundi_de_paques = new Date(paques.getTime());
  lundi_de_paques.setDate(paques.getDate() + 1);
  const ascension = new Date(paques.getTime());
  ascension.setDate(paques.getDate() + 39);
  const lundi_de_pentecote = new Date(paques.getTime());
  lundi_de_pentecote.setDate(paques.getDate() + 50);
  const dates = [
    jour_de_lan,
    fete_du_travail,
    victoire_des_allies,
    fete_nationale,
    assomption,
    toussaint,
    armistice,
    noel,
    lundi_de_paques,
    ascension,
    lundi_de_pentecote,
  ];
  cache[year] = dates.map((d) => d.getTime());
  return cache[year];
}

export function is_public_holiday(day: Date) {
  const year = day.getFullYear();
  return _get_public_holidays_in_year(year).indexOf(day.getTime()) !== -1;
}

export function is_working_day(day: Date) {
  const sunday = 0;
  const saturday = 6;
  const weekDay = day.getDay();
  return weekDay !== sunday && weekDay !== saturday && !is_public_holiday(day);
}
