import { useState, useCallback, useLayoutEffect } from "react";
import { debounce } from "lodash";
import { DimensionObject, UseDimensionsArgs, UseDimensionsHook } from "./types";

function getDimensionObject(node: HTMLElement | null): DimensionObject {
  if (!node)
    return {
      width: 0,
      height: 0,
      x: 0,
      y: 0,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    };
  const rect = node.getBoundingClientRect();
  return {
    width: rect.width,
    height: rect.height,
    x: rect.x,
    y: rect.y,
    top: rect.top,
    left: rect.left,
    right: rect.right,
    bottom: rect.bottom
  };
}

function useDimensions({
  updateOnResize = true,
  debounceMs = 250
}: UseDimensionsArgs = {}): UseDimensionsHook {
  const [dimensions, setDimensions] = useState({});
  const [node, setNode] = useState(null);

  const ref = useCallback(node => {
    setNode(node);
  }, []);

  useLayoutEffect(() => {
    if (node) {
      const measure = () =>
        window.requestAnimationFrame(() =>
          setDimensions(getDimensionObject(node))
        );
      measure();
      const debouncedMeasure = debounce(measure, debounceMs);
      if (updateOnResize) {
        window.addEventListener("resize", debouncedMeasure);
        return () => {
          window.removeEventListener("resize", debouncedMeasure);
        };
      }
    }
  }, [node, updateOnResize, debounceMs]);

  return [ref, dimensions, node];
}

export default useDimensions;
