import { Formik } from "formik";
import * as Yup from "yup";
import { Input } from "../ui";

export const LoginSchema = Yup.object({
  email: Yup.string()
    .email("Veuillez entrer un email valide")
    .required("Votre email est obligatoire"),
  password: Yup.string()
    .min(8, "Votre mot de passe est trop court")
    .required("Votre mot de passe est obligatoire"),
});

const LoginForm = ({
  id,
  onSubmit,
  className,
  initialValues = { email: "", password: "" },
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={LoginSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <form
          id={id}
          className={className}
          onSubmit={handleSubmit}
          aria-label="form"
        >
          <Input
            id="login_form-email"
            data-testid="login_form-email"
            label="Email"
            placeholder="Votre email"
            name="email"
            className="mb-4"
          />
          <Input
            id="login_form-password"
            data-testid="login_form-password"
            label="Mot de passe"
            placeholder="Votre mot de passe"
            name="password"
            type="password"
            className="mb-4"
          />
        </form>
      )}
    </Formik>
  );
};

export default LoginForm;
