import { useEffect, useState } from "react";
import http from "./services/http";
import { calculPrixChantier } from "./calculateur/prixChantier";
import Joyride, { STATUS } from "react-joyride";

export default function PageCalculCoutChantier() {
  const [voirTutoriel, setVoirTutoriel] = useState(false);
  const [statut, setStatut] = useState("autoEntrepreneur");
  const [salaireSouhaite, setSalaireSouhaite] = useState("");
  const [marge, setMarge] = useState("7");
  const [semainesConges, setSemainesConges] = useState("5");
  const [joursSurChantier, setJoursSurChantier] = useState("");
  const [fraisMensuel, setFraisMensuel] = useState("1500");
  const [budgetMatieresPremieres, setBudgetMatieresPremieres] = useState("");
  const [form, setForm] = useState({});
  const [estimationMontantDevis, setEstimationMontantDevis] = useState(null);

  useEffect(() => {
    try {
      const form = {
        statut,
        salaireSouhaite: Number(salaireSouhaite.replace(" ", "")),
        marge: Number(marge.replace(" ", "").replace("%", "")),
        semainesConges: Number(semainesConges.replace(" ", "")),
        joursSurChantier: Number(joursSurChantier.replace(" ", "")),
        fraisMensuel: Number(fraisMensuel.replace(" ", "")),
        budgetMatieresPremieres: Number(
          budgetMatieresPremieres.replace(" ", "")
        ),
      };
      setForm(form);
    } catch (error) {}
  }, [
    statut,
    salaireSouhaite,
    marge,
    semainesConges,
    joursSurChantier,
    fraisMensuel,
    budgetMatieresPremieres,
  ]);

  useEffect(() => {
    http.get("img/logo.jpg");
  }, []);

  useEffect(() => {
    setFraisMensuel(statut === "autoEntrepreneur" ? "1500" : "2000");
  }, [statut]);

  return (
    <>
      {estimationMontantDevis === null ? null : (
        <div className="z-20 fixed w-full h-full top-0 left-0 flex items-center justify-center">
          <div
            className="z-30 absolute w-full h-full bg-gray-900 opacity-50"
            onClick={() => setEstimationMontantDevis(null)}
          ></div>

          <div
            className={`z-40 bg-white w-11/12 lg:max-w-4xl mx-auto rounded shadow-lg block`}
            style={{
              maxHeight: "90vh",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <div className="z-40 py-4 text-left px-6">
              <div className="z-40 flex justify-between items-center">
                <h1 className="h2 mb-0" id="estimationMontantDevis">
                  Prix du chantier estimé{" "}
                  <span className="text-green-500">
                    {estimationMontantDevis}€{" "}
                    {statut === "autoEntrepreneur" ? "TTC" : "HT"}
                  </span>
                </h1>
                <div
                  className="z-40 cursor-pointer"
                  onClick={() => setEstimationMontantDevis(null)}
                >
                  <svg
                    className="icon mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                  >
                    <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Joyride
        run={voirTutoriel}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        continuous={true}
        disableOverlay={true}
        disableOverlayClose={true}
        //hideBackButton={true}
        locale={{
          back: "Précédent",
          close: "Fermer",
          last: "Fermer",
          next: "Suivant",
          open: "Ouvrir",
          skip: "Passer",
        }}
        styles={{ options: { primaryColor: "rgba(88, 80, 236)" } }}
        steps={[
          {
            target: "body",
            content: (
              <>
                <h2 className="h2">Commençons la visite guidée</h2>
                <p>
                  Cet outil vous donne le prix que vous devez facturez sur un
                  chantier en fonction du temps que vous allez y passer. Le
                  calcul est très simple. Il répartit les coûts fixes de votre
                  structure sur tous les chantiers que vous pourriez faire sur
                  l'année.
                </p>
              </>
            ),
            disableBeacon: true,
            placement: "center",
          },
          {
            target: "#statut",
            content:
              "Selectionner le statut de votre structure. Auto entrepreneur ou société.",
            disableBeacon: true,
          },
          {
            target: "#salaireSouhaite",
            content: `Quel est le salaire net que vous voulez à la fin du mois ? Exemple: 2200€. Ce salaire est ensuite pondéré par ${
              statut === "autoEntrepreneur" ? "1.4" : "1.6"
            } pour prendre en compte les charges.`,
            disableBeacon: true,
          },
          {
            target: "#joursSurChantier",
            content: "Combien de jours pensez-vous passer sur ce chantier ?",
            disableBeacon: true,
          },
          {
            target: "#fraisMensuel",
            content:
              "Ce montant correspond à vos frais que vous devez amortir sur tous les chantiers. Exemple: l'assurance décennale, le loyer de vos locaux, l'essence ou les paniers repas.",
            disableBeacon: true,
          },
          {
            target: "#budgetMatieresPremieres",
            content: `Le montant des matières premières et consommables sur ce chantier.`,
            disableBeacon: true,
          },
          {
            target: "#marge",
            content:
              "Le pourcentage de bénéfices que vous souhaitez atteindre. Faites varier ce pourcentage pour être plus ou moins compétitif.",
            disableBeacon: true,
          },
          {
            target: "#semainesConges",
            content:
              "Le calculateur prend en compte les congès. Il amorti le coût des congès sur chaque chantier. Combien de semaine de vacances vous souhaitez cette année?",
            disableBeacon: true,
          },
          {
            target: "#calculer",
            content: "Maintenant vous pouvez lancer le calcul",
            disableBeacon: true,
          },
        ]}
        callback={({ statut }) => {
          const finishedStatutes = [STATUS.FINISHED, STATUS.SKIPPED];

          if (finishedStatutes.includes(statut)) {
            setVoirTutoriel(false);
          }
        }}
      />
      <div className="my-8 mx-auto max-w-screen-xl px-4 sm:my-12 sm:px-6 md:my-10 xl:my-14">
        <form
          className="space-y-8 divide-y divide-gray-200"
          onSubmit={(e) => {
            e.preventDefault();
            setVoirTutoriel(false);
            setEstimationMontantDevis(
              calculPrixChantier(form).estimationMontantDevis
            );
          }}
        >
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Calculer le prix d'un chantier de peinture
              </h3>
              <div className="my-1 max-w-2xl text-sm text-gray-500">
                Cet outil vous donne le prix que vous devez facturez sur un
                chantier en fonction du temps que vous allez y passer.
              </div>
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setVoirTutoriel(true);
                }}
              >
                Comment ça marche ? Lancez la visite guidée.
              </button>
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="statut"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Statut
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <select
                      id="statut"
                      name="statut"
                      value={statut}
                      onChange={(e) => setStatut(e.target.value)}
                      className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    >
                      <option value="autoEntrepreneur">
                        Auto entrepreneur
                      </option>
                      <option value="societe">Société</option>
                    </select>
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="salaireSouhaite"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Salaire souhaité (€)
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="number"
                      name="salaireSouhaite"
                      required
                      value={salaireSouhaite}
                      onChange={(e) => setSalaireSouhaite(e.target.value)}
                      id="salaireSouhaite"
                      className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="joursSurChantier"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Jours sur le chantier
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="number"
                        name="joursSurChantier"
                        required
                        value={joursSurChantier}
                        onChange={(e) => setJoursSurChantier(e.target.value)}
                        id="joursSurChantier"
                        className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="fraisMensuel"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Frais mensuel{" "}
                    {statut === "autoEntrepreneur"
                      ? "TTC (€)"
                      : "de l'entreprise (€)"}
                    <br />
                    <span className="text-gray-500 text-sm">
                      Véhicules, repas, assurances, loyer, ...
                    </span>
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      id="fraisMensuel"
                      type="number"
                      name="fraisMensuel"
                      value={fraisMensuel}
                      onChange={(e) => setFraisMensuel(e.target.value)}
                      className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="budgetMatieresPremieres"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Budget matières premières{" "}
                    {statut === "autoEntrepreneur" ? "TTC (€)" : "(€)"}
                    <br />
                    <span className="text-gray-500 text-sm">
                      Si ce montant est vide, l'outil utilise{" "}
                      {statut === "autoEntrepreneur" ? "24%" : "20%"} du prix du
                      chantier en matière première.
                    </span>
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      id="budgetMatieresPremieres"
                      type="number"
                      name="budgetMatieresPremieres"
                      required
                      value={budgetMatieresPremieres}
                      onChange={(e) =>
                        setBudgetMatieresPremieres(e.target.value)
                      }
                      className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="marge"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Marge de l'entreprise (%)
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      id="marge"
                      type="number"
                      name="marge"
                      required
                      value={marge}
                      onChange={(e) => setMarge(e.target.value)}
                      className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="semainesConges"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Congés par an (en semaines)
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="number"
                      name="semainesConges"
                      required
                      value={semainesConges}
                      onChange={(e) => setSemainesConges(e.target.value)}
                      id="semainesConges"
                      className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-5">
            <div className="flex">
              <button id="calculer" type="submit" className="btn btn-primary">
                Calculer
              </button>
            </div>
          </div>
        </form>
        <div>
          <p className="text-gray-500 text-sm">
            Créé par <a href="/auth/signin">Piloter Ma PME</a>, logiciel de
            gestion d'emploi du temps pour les artisans du bâtiment.
          </p>
          <p className="text-gray-500 text-sm">
            Une idée? Un retour pour améliorer l'outil? Envoyez nous un email à{" "}
            <a href="mailto:contact@pilotermapme.fr">contact@pilotermapme.fr</a>
            .
          </p>
        </div>
      </div>
    </>
  );
}
