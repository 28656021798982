import * as types from "./planningActionsTypes";
import { DateTime } from "luxon";

export function setPlanning(planning) {
  return {
    type: types.SET_PLANNING,
    planning,
  };
}

export function moveSegment(segmentsPerResource, segment, toResourceId, start) {
  const fromResourceId = segment.resourceId || "toplan";
  const { segments, segmentDeplaced } = segmentsPerResource[
    fromResourceId
  ].segments.reduce(
    (acc, s) => {
      if (s.id === segment.id) {
        acc.segmentDeplaced = { ...s };
      } else {
        acc.segments.push(s);
      }
      return acc;
    },
    { segments: [], segmentDeplaced: null }
  );
  segmentsPerResource[fromResourceId].segments = segments;
  return {
    ...segmentDeplaced,
    start: start.toISODate(),
    end: start.plus({ days: segmentDeplaced.nbDays - 1 }).toISODate(),
    resourceId: toResourceId,
  };
}

export function resizeSegment(segmentsPerResource, segment, nbDays) {
  const resourceId = segment.resourceId || "toplan";
  const { segments, segmentResized } = segmentsPerResource[
    resourceId
  ].segments.reduce(
    (acc, s) => {
      if (s.id === segment.id) {
        acc.segmentResized = s;
      } else {
        acc.segments.push(s);
      }
      return acc;
    },
    { segments: [], segmentResized: null }
  );
  segmentsPerResource[resourceId].segments = segments;
  return {
    ...segmentResized,
    end: DateTime.fromISO(segmentResized.end)
      .plus({ days: nbDays })
      .toISODate(),
  };
}

export function duplicateASegment(segment) {
  return (dispatch, getState) => {
    const { tasks } = getState();
    const task = tasks[segment.taskId];
    const newSegment = {
      ...segment,
    };
    delete newSegment.id;
    task.segments.push(newSegment);
    return Promise.resolve(task);
  };
}
