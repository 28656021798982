import { DateTime } from "luxon";
import { range } from "lodash";
import { is_working_day } from "../core/date-utils";

export type Day = {
  date: DateTime;
  isoDate: string;
  isToday: boolean;
  weekDay: number;
  isFirstDayOfTheMonth: boolean;
  isFirstDayOfInterval: boolean;
  isWeekEndOrPublicHoliday: boolean;
  isEvenMonth: boolean;
};

//todo test me
export function getDays(
  firstDayOfInterval: DateTime,
  now: DateTime,
  nbDays: number
) {
  return range(nbDays).reduce((acc, dayIndex) => {
    const currentDay = firstDayOfInterval.plus({ days: dayIndex });
    acc.push({
      date: currentDay,
      isoDate: currentDay.toISODate(),
      isToday: currentDay.hasSame(now, "day"),
      isFirstDayOfTheMonth: currentDay.get("day") === 1,
      weekDay: currentDay.weekday,
      isFirstDayOfInterval: dayIndex === 0,
      isEvenMonth: currentDay.month % 2 === 0,
      isWeekEndOrPublicHoliday: !is_working_day(currentDay.toJSDate()),
    });
    return acc;
  }, [] as Day[]);
}

type Segment = {
  nbDays: number;
  start: string;
  end: string;
};

type Position = {
  width: number;
  height: number;
  left: number;
  top: number;
};

type SegmentWithPosition = Segment & Position;

type Calendar = {
  segmentMargin: number;
  rowHeight: number;
  dayWidth: number;
  firstDayOfInterval: DateTime;
  lastDayOfInterval: DateTime;
};

export function addSegmentPosition(segments: Segment[], calendar: Calendar) {
  const { firstDayOfInterval, lastDayOfInterval, dayWidth } = calendar;
  const segmentsWithPosition: SegmentWithPosition[] = [];
  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i];
    const start = DateTime.fromISO(segment.start);
    const end = DateTime.fromISO(segment.end);
    if (end < firstDayOfInterval || start > lastDayOfInterval) continue;
    const nbDaysOffsetLeft = start.diff(firstDayOfInterval, "days")["days"];
    const nbDaysToRemoveLeft =
      nbDaysOffsetLeft < 0 ? Math.abs(nbDaysOffsetLeft) : 0;
    const nbDaysOffsetRight = end.diff(lastDayOfInterval, "days")["days"];
    const nbDaysToRemoveRight = nbDaysOffsetRight > 0 ? nbDaysOffsetRight : 0;
    const left = nbDaysToRemoveLeft === 0 ? nbDaysOffsetLeft * dayWidth : 0;
    segmentsWithPosition.push({
      ...segment,
      width:
        dayWidth * (segment.nbDays - nbDaysToRemoveLeft - nbDaysToRemoveRight),
      height: 0,
      left,
      top: 0,
    });
  }

  return withoutCollision(segmentsWithPosition, calendar);
}

function unbufferize(buffer: SegmentWithPosition[], calendar: Calendar) {
  const { rowHeight, segmentMargin } = calendar;
  const bufferLength = buffer.length;
  const marginHeight = segmentMargin;
  const segmentHeight = Math.round(
    (rowHeight - 2 * marginHeight) / bufferLength
  );
  const segments: SegmentWithPosition[] = [];
  let verticalOffset = marginHeight;
  for (let i = 0; i < buffer.length; i++) {
    const segment = buffer[i];
    segments.push({
      ...segment,
      height: segmentHeight,
      top: verticalOffset,
    });
    verticalOffset += segmentHeight;
  }
  return segments;
}

export function withoutCollision(
  segments: SegmentWithPosition[],
  calendar: Calendar
): SegmentWithPosition[] {
  return segments
    .sort((s1, s2) => {
      if (s1.left === s2.left) return 0;
      return s1.left < s2.left ? -1 : 1;
    })
    .reduce(
      (acc, segment, index) => {
        const bufferLength = acc.buffer.length;
        if (bufferLength > 0) {
          const previousSegment = acc.buffer[bufferLength - 1];
          if (segment.left >= previousSegment.left + previousSegment.width) {
            const unbufferizeSegments = unbufferize(acc.buffer, calendar);
            acc.results = acc.results.concat(unbufferizeSegments);
            acc.buffer = [];
          }
        }
        let isLastElement = index === segments.length - 1;
        if (isLastElement) acc.buffer.push(segment);
        if (isLastElement) {
          const unbufferizeSegments = unbufferize(acc.buffer, calendar);
          acc.results = acc.results.concat(unbufferizeSegments);
          acc.buffer = [];
        }
        acc.buffer.push(segment);
        return acc;
      },
      {
        buffer: [],
        results: [],
      } as {
        buffer: SegmentWithPosition[];
        results: SegmentWithPosition[];
      }
    ).results;
}

export function moveSegmentBeginningOfRow(
  segments: Segment[],
  calendar: Calendar
) {
  const orderedSegment = segments.sort((s1, s2) => {
    const s1Start = DateTime.fromISO(s1.start);
    const s2Start = DateTime.fromISO(s2.start);
    if (s1Start.hasSame(s2Start, "day")) return 0;
    return s1Start < s2Start ? -1 : 1;
  });
  const { dayWidth, rowHeight, segmentMargin } = calendar;
  const segmentsWithPosition: SegmentWithPosition[] = [];
  let nbDaysShift = 0;
  for (let i = 0; i < orderedSegment.length; i++) {
    const segment = orderedSegment[i];
    const width = dayWidth * segment.nbDays;
    segmentsWithPosition.push({
      ...segment,
      width,
      height: rowHeight - 2 * segmentMargin,
      left: dayWidth * nbDaysShift,
      top: segmentMargin,
    });
    nbDaysShift += segment.nbDays;
  }
  return segmentsWithPosition;
}
