import { useField } from "formik";
import Label from "./Label";
import HelpMessage from "./HelpMessage";

export const InputEuro = ({ label, className, ...props }) => {
  const [field, meta] = useField(props);
  const { value, ...rest } = field;
  return (
    <div className={`mb-4 ${className}`}>
      <Label htmlFor={props.id || props.name}>{label}</Label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm sm:leading-5">€</span>
        </div>
        <input
          className="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
          placeholder="0.00"
          value={value}
          {...rest}
          {...props}
        />
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm sm:leading-5">EUR</span>
        </div>
      </div>
      {meta.touched && meta.error ? (
        <HelpMessage className="text-red-800">{meta.error}</HelpMessage>
      ) : null}
    </div>
  );
};

export default InputEuro;
