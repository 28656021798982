import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Modal, ConfirmDeleteModal } from "../ui";
import TaskForm from "./TaskForm";
import { hideTaskModal } from "./taskActions";
import { FaTrashAlt } from "react-icons/fa";
import { removeTask } from "../tasks/tasksActions";
import { useEffect, useState } from "react";
import { getResources } from "../services/api";
import { useAuth } from "../auth/authContext";

function ReadOnlyModal({ task }) {
  const dispatch = useDispatch();
  return (
    <Modal
      title={`Chantier ${task.name}`}
      isOpen
      size="4xl"
      close={() => dispatch(hideTaskModal())}
    >
      <div>
        <div className="flex -mx-2 mb-4">
          <div className="w-full px-2">
            Documents
          </div>
        </div>
        <div className="flex mb-8 pl-8">
          <ul className="list-disc">
            {task.documents.map((document, i) => (
              <li key={i}>
                <a
                  href={document.document}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {document.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Modal>
  );
}

function OwnerTaskModal({ task, createANewTask, updateATask, deleteATask }) {
  const dispatch = useDispatch();
  const [resources, setResources] = useState([]);
  useEffect(() => {
    getResources().then((resources) => setResources(resources));
  }, []);
  if (isEmpty(task)) return null;
  const isATaskCreation = isEmpty(task.id);
  const nbSegments = task.segments.length;
  const confirmDeleteModal = (
    <ConfirmDeleteModal
      key="task_form-confirm_delete_button"
      title="Supprimer un chantier"
      confirmTextButton={`Oui supprimer ${task.name}!`}
      message={
        <p>
          Etes vous sur de vouloir supprimer {task.name}?
          <br />
          <span className="text-red-800 font-bold">
            Vous avez {nbSegments} période{nbSegments > 1 ? "s" : ""} associée
            {nbSegments > 1 ? "s" : ""} au chantier {task.name}.
          </span>
        </p>
      }
      confirmed={() => {
        dispatch(hideTaskModal());
        deleteATask();
      }}
      button={(toggle) => (
        <button
          key="task_form-delete_button"
          type="button"
          className="btn btn-danger btn-icon"
          onClick={toggle}
        >
          <FaTrashAlt className="icon mr-2" /> Supprimer
        </button>
      )}
    />
  );
  const actionsLeft = isATaskCreation ? [] : [confirmDeleteModal];
  return (
    <Modal
      title={
        isATaskCreation
          ? "Créer un nouveau chantier"
          : `Modifier le chantier ${task.name}`
      }
      isOpen
      size="4xl"
      close={() => dispatch(hideTaskModal())}
      actionsLeft={actionsLeft}
      actionsRight={[
        <button
          key="task_form-cancel_button"
          type="button"
          className="btn btn-secondary mr-2"
          onClick={() => dispatch(hideTaskModal())}
        >
          Annuler
        </button>,
        <button
          key="task_form-submit_button"
          form="task_form"
          type="submit"
          className="btn btn-primary"
        >
          {isATaskCreation ? "Créer" : "Mettre à jour"}
        </button>,
      ]}
    >
      <TaskForm
        initialValues={task}
        onDelete={() => dispatch(removeTask(task))}
        onChange={(newTask) => {
          if (!isATaskCreation) {
            updateATask(newTask);
          }
        }}
        onSubmit={(newTask) => {
          dispatch(hideTaskModal());
          if (isATaskCreation) {
            return createANewTask(newTask);
          }
          return updateATask(newTask);
        }}
        resources={resources}
        className="mt-10 mb-12 h-full"
      />
    </Modal>
  );
}

const TaskModal = ({ createANewTask, updateATask, deleteATask }) => {
  const { task } = useSelector((state) => ({
    task: state.task,
  }));

  const { currentUser } = useAuth();
  if (currentUser.is_reader && task) {
    return <ReadOnlyModal task={task} />;
  }
  return (
    <OwnerTaskModal
      createANewTask={createANewTask}
      updateATask={updateATask}
      deleteATask={deleteATask}
      task={task}
    />
  );
};

export default TaskModal;
