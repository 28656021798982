import { useField } from "formik";
import Label from "./Label";

export const Checkbox = ({ children, className, ...props }) => {
  const [field] = useField({ ...props, type: "checkbox" });
  return (
    <Label className={`flex items-center ${className}`}>
      <input {...field} {...props} type="checkbox" />
      <span className="ml-2">{children}</span>
    </Label>
  );
};

export default Checkbox;
