import { Redirect, useLocation } from "react-router-dom";
import * as queryString from "query-string";

const AuthPage = () => {
  const location = useLocation();
  const { mode, oobCode: code } = queryString.parse(location.search);
  return <Redirect to={`/auth/${mode}/${code}`} />;
};

export default AuthPage;
