import * as types from "./taskActionsTypes";

const initialState = null;

export default function reduce(state = initialState, action) {
  switch (action.type) {
    case types.SHOW_TASK_MODAL:
      return action.task;
    case types.HIDE_TASK_MODAL:
      return initialState;
    default:
      return state;
  }
}
