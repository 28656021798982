import { useEffect, useRef, useState } from "react";
import { Header } from "../ui";

function ResumeMoisEnCours() {
  return (
    <div>
      <div className="mt-0 grid grid-cols-1 gap-5 sm:grid-cols-3">
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <dl>
              <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                Chiffre d'affaire par jour
              </dt>
              <dd className="mt-1 text-3xl leading-9 font-semibold text-gray-900">
                125 €
              </dd>
            </dl>
          </div>
        </div>
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <dl>
              <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                Chiffre d’affaire prévisionnel mois en cours
              </dt>
              <dd className="mt-1 text-3xl leading-9 font-semibold text-gray-900">
                125 €
              </dd>
            </dl>
          </div>
        </div>
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <dl>
              <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                Carnet de commande
              </dt>
              <dd className="mt-1 text-3xl leading-9 font-semibold text-gray-900">
                125 €
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}

interface CalendrierProps {
  width: number;
}

function Calendrier({ width }: CalendrierProps) {
  return (
    <div className="bg-white overflow-hidden shadow rounded-lg h-20">
      <div>header</div>
      <div>{width}</div>
      <div>bottom</div>
    </div>
  );
}

export default function PageEmploiDuTemps() {
  const mainRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  useEffect(() => {
    setWidth(mainRef.current ? mainRef.current.offsetWidth : 0);
  }, [mainRef]);
  return (
    <div>
      <Header />
      <main className="p-4" ref={mainRef}>
        {width ? (
          <div>
            <ResumeMoisEnCours />
            <div className="h-5 w-full" />
            <Calendrier width={width} />
          </div>
        ) : null}
      </main>
    </div>
  );
}
