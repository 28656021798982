import { IStats } from "../core/types";

export function DashboardHeader({ stats }: { stats: IStats; }) {
  return (
    <div className="mb-4">
      <div className="mt-0 grid grid-cols-1 gap-5 sm:grid-cols-3">
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <dl>
              <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                Chiffre d'affaire par jour
              </dt>
              <dd className="mt-1 text-3xl leading-9 font-semibold text-gray-900">
                {stats.total.meanTurnoverPerWorkedDay} €
              </dd>
            </dl>
          </div>
        </div>
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <dl>
              <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                Chiffre d’affaire prévisionnel mois en cours
              </dt>
              <dd className="mt-1 text-3xl leading-9 font-semibold text-gray-900">
                {stats.total.turnoverCurrentMonth} €
              </dd>
            </dl>
          </div>
        </div>
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <dl>
              <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                Carnet de commande
              </dt>
              <dd className="mt-1 text-3xl leading-9 font-semibold text-gray-900">
                {stats.total.remainingTurnover} €
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}
