import { useEffect, useContext, useCallback } from "react";
import domtoimage from "dom-to-image";
import FileSaver from "file-saver";
import {
  FaAngleLeft,
  FaAngleRight,
  FaCalendar,
  FaCamera,
  FaPlus,
} from "react-icons/fa";
import { CalendarContext } from "./Calendar";
import TaskModal from "../task/TaskModal";
import { useAuth } from "../auth/authContext";

const LeftNavigation = () => {
  const { showTaskModal, createANewTask, updateATask, deleteATask } =
    useContext(CalendarContext);
  const { currentUser } = useAuth();
  return (
    <div>
      <TaskModal
        createANewTask={createANewTask}
        updateATask={updateATask}
        deleteATask={deleteATask}
      />
      <button
        type="button"
        className={`btn btn-primary btn-sm btn-icon mb-4 md:mb-0 ${
          currentUser.is_reader ? "btn-disabled" : ""
        }`}
        onClick={showTaskModal}
      >
        <FaPlus className="icon mr-2" />
        Créer un chantier
      </button>
    </div>
  );
};

const RightNavigation = () => {
  const { currentUser } = useAuth();
  const { now, date, setDate, enableKeyboardEvent, setShowTurnover, nbDays } =
    useContext(CalendarContext);

  const nbDaysShift = nbDays <= 7 ? 1 : 7;

  const setPreviousWeek = useCallback(() => {
    setDate(date.minus({ days: nbDaysShift }));
  }, [setDate, date, nbDaysShift]);

  const resetWeek = useCallback(() => {
    setDate(now);
  }, [setDate, now]);

  const setNextWeek = useCallback(() => {
    setDate(date.plus({ days: nbDaysShift }));
  }, [setDate, date, nbDaysShift]);

  useEffect(() => {
    function handlekeydownEvent(event) {
      const { keyCode } = event;
      const LEFT_ARROW_KEY_CODE = 37;
      if (keyCode === LEFT_ARROW_KEY_CODE && enableKeyboardEvent) {
        setPreviousWeek();
      }
      const RIGHT_ARROW_KEY_CODE = 39;
      if (keyCode === RIGHT_ARROW_KEY_CODE && enableKeyboardEvent) {
        setNextWeek();
      }
    }
    document.addEventListener("keyup", handlekeydownEvent);
    return () => {
      document.removeEventListener("keyup", handlekeydownEvent);
    };
  }, [setPreviousWeek, setNextWeek, enableKeyboardEvent]);
  return (
    <div className="flex justify-between items-center">
      {currentUser.is_reader ? null : (
        <button
          className="btn btn-secondary btn-icon btn-sm mr-2"
          onClick={() => {
            setShowTurnover(false);
            const node = document.getElementById("calendar");
            domtoimage
              .toBlob(node)
              .then((blob) => {
                FileSaver.saveAs(blob, "calendar.png");
              })
              .finally(() => {
                setShowTurnover(true);
              });
          }}
        >
          <FaCamera className="icon" />
        </button>
      )}
      <button
        className="btn btn-secondary btn-icon btn-sm mr-2"
        onClick={resetWeek}
      >
        <FaCalendar className="icon mr-2" /> {now.toFormat("ccc d LLL")}
      </button>
      <button
        className="btn btn-secondary btn-sm mr-2"
        onClick={() => setPreviousWeek()}
      >
        <FaAngleLeft className="icon" />
      </button>
      <button
        className="btn btn-secondary btn-sm"
        onClick={() => setNextWeek()}
      >
        <FaAngleRight className="icon" />
      </button>
    </div>
  );
};

const CalendarTitle = () => {
  const { firstDayOfInterval, lastDayOfInterval } = useContext(CalendarContext);
  const firstMonth = firstDayOfInterval.toFormat("LLLL");
  const lastMonth = lastDayOfInterval.toFormat("LLLL");
  const firstYear = firstDayOfInterval.toFormat("yyyy");
  const lastYear = lastDayOfInterval.toFormat("yyyy");
  const title =
    firstYear === lastYear
      ? firstMonth === lastMonth
        ? `${lastMonth} ${lastYear}`
        : `${firstMonth} - ${lastMonth} ${lastYear}`
      : `${firstMonth} ${firstYear} - ${lastMonth} ${lastYear}`;
  return (
    <h3 className="text-center text-indigo-600 text-sm uppercase mb-4 md:mb-0">
      {title}
    </h3>
  );
};

const CalendarNavigation = () => {
  return (
    <div className="flex flex-col md:flex-row justify-between items-center p-4 border-0 border-b border-solid">
      <LeftNavigation />
      <CalendarTitle />
      <RightNavigation />
    </div>
  );
};

export default CalendarNavigation;
