import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showSuccess, showError } from "../alerts/alertsActions";
import LoginBox from "./LoginBox";
import { useAuth } from "./authContext";
import RegisterForm from "./RegisterForm";

export const buildErrorMessage = (data) => {
  try {
    if (
      data.email &&
      data.email.join() ===
        "Un objet Utilisateur avec ce champ email address existe déjà."
    ) {
      return 'Un utilisateur avec cet email existe déjà. Connectez vous en cliquant sur le lien "Déja un compte? Connectez vous".';
    } else if (
      data.password &&
      data.password.join() === "Ce mot de passe est trop courant."
    ) {
      return "Ce mot de passe est trop courant. Veuillez choisir un mot de passe plus fort.";
    } else {
      const errors = Object.values(data).reduce((acc, d) => {
        acc.push(d.join());
        return acc;
      }, []);
      return errors.join("\n");
    }
  } catch {
    return "Nous sommes désolés, il nous est impossible de créer un compte pour le moment. Veuillez retentez dans quelques minutes.";
  }
};

const RegisterPage = () => {
  const history = useHistory();
  const { register } = useAuth();
  const dispatch = useDispatch();
  return (
    <LoginBox>
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h1 className="my-2 font-semibold text-xl text-gray-900">
          Créer un compte
        </h1>
        <p className="mb-8">
          ou
          <Link to="/auth/login" className="no-underline ml-2">
            connectez vous
          </Link>
        </p>
        <RegisterForm
          id="register_form"
          onSubmit={(values) => {
            const { company, email, password } = values;
            register({ company, email, password })
              .then(() => {
                dispatch(
                  showSuccess(
                    "Merci d'avoir créé un compte sur Piloter Ma PME",
                    "Commencez à utiliser Piloter Ma PME gratuitement pendant 30 jours."
                  )
                );
                history.push("/calendar");
              })
              .catch((error) => {
                if (error.response) {
                  const statusCode = error.response.status;
                  if (statusCode === 400) {
                    const errorMessage = buildErrorMessage(error.response.data);
                    dispatch(
                      showError("Impossible de créer un compte", errorMessage)
                    );
                  }
                } else {
                  dispatch(
                    showError(
                      "Impossible de créer un compte",
                      "Veuillez retentez dans quelques minutes"
                    )
                  );
                }
              });
          }}
        />
        <div className="flex items-center justify-between mt-8 mb-6">
          <button
            type="submit"
            form="register_form"
            className="btn btn-primary"
          >
            Créer un compte
          </button>
        </div>
        <div className="text-gray-900 text-sm mb-6">
          En créant un compte vous accepter les{" "}
          <Link to="/cgucgv" className="no-underline">
            CGU/CGV
          </Link>
        </div>
      </div>
    </LoginBox>
  );
};

export default RegisterPage;
