export interface IForm {
  statut: "autoEntrepreneur" | "societe";
  salaireSouhaite: number;
  marge: number;
  semainesConges: number;
  joursSurChantier: number;
  fraisMensuel: number;
  budgetMatieresPremieres: number;
}

export function calculPrixChantier(form: IForm) {
  const ponderationsSalaires = {
    autoEntrepreneur: 1.4,
    societe: 1.6,
  };
  const pourcentageFraisMatierePremiere = {
    autoEntrepreneur: 24,
    societe: 20,
  };
  const ponderation = ponderationsSalaires[form.statut];
  const salaireMensuelCharge =
    form.salaireSouhaite * ponderation * (1 + form.semainesConges / 52);
  const jourDansLeMoisTravaillé = (52 * 5) / 12;
  const fraisPeinture =
    form.budgetMatieresPremieres === 0
      ? (form.joursSurChantier *
          (salaireMensuelCharge / jourDansLeMoisTravaillé) *
          pourcentageFraisMatierePremiere[form.statut]) /
        100
      : form.budgetMatieresPremieres;
  const estimationMontantDevis =
    ((form.joursSurChantier * (salaireMensuelCharge + form.fraisMensuel)) /
      jourDansLeMoisTravaillé +
      fraisPeinture) *
    (1 + form.marge / 100);
  return {
    salaireMensuelCharge: Math.round(salaireMensuelCharge),
    estimationMontantDevis: Math.round(estimationMontantDevis),
  };
}
