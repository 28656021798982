import React from "react";

function Card({
  children,
  className = "",
  ...props
}: {
  children: React.ReactNode;
  className?: string;
  [k: string]: any;
}) {
  return (
    <div className={`bg-white rounded shadow ${className}`} {...props}>
      {children}
    </div>
  );
}

export default Card;
