import LoadingIcon from "./LoadingIcon";

const Loader = ({ className = "", ...props }) => (
  <div
    {...props}
    className={`w-full h-full flex justify-center items-center ${className}`}
  >
    <LoadingIcon />
  </div>
);

export default Loader;
